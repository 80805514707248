const INITIAL_STATE = { token: {}, message: "", error: "", isAuthenticated: false, sending: false, success: false }



export default (state = INITIAL_STATE, { type, payload }) => {
   switch (type) {


      // #region -------------------------- REGISTER ------------------------------ //


      case "REGISTER_PENDING":
         return { ...state, sending: true }

      case "REGISTER_FULFILLED":
         return { ...state, message: "Kayıt Başarılı", error: "", sending: false, success: true }

      case "REGISTER_REJECTED":
         return { ...state, error: payload.response.data.error.details.messages.email[0] === "Email already exists" ? "E-posta adresi zaten kayıtlı." : payload.message, sending: false }


      // #endregion


      // #region --------------------------- LOGIN --------------------------------- //


      case "LOGIN_PENDING":
         return { ...state, error: "", sending: true }

      case "LOGIN_FULFILLED":
         return { ...state, token: payload.data, isAuthenticated: true, error: "", sending: false }

      case "LOGIN_REJECTED":
         return { ...state, error: "Kullanıcı adı yada şifre hatalı", sending: false }


      // #endregion


      // #region ----------------------- CHANGE_PASSWORD --------------------------- //


      case "CHANGE_PASSWORD_PENDING":
         return { ...state, error: "", sending: true }

      case "CHANGE_PASSWORD_FULFILLED":
         return { ...state, message: "Şifre başarıyla değiştirildi.", error: "", sending: false, success: true }

      case "CHANGE_PASSWORD_REJECTED":
         return { ...state, error: "Şifre hatalı", sending: false }


      // #endregion


      // #region --------------------------- LOGOUT -------------------------------- //

      case "LOGOUT_PENDING":
         return { ...INITIAL_STATE }

      case "LOGOUT_FULFILLED":
         return { ...INITIAL_STATE }

      case "LOGOUT_REJECTED":
         return { ...INITIAL_STATE }


      // #endregion


      // #region --------------------------- CLEAR --------------------------------- //


      case "CLEAR":
         return { ...INITIAL_STATE }

      case "CLEAR_MESSAGE":
         return { ...state, message: "", error: "", sending: false, success: false }


      // #endregion


      default:
         return state
   }
}