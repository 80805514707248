import React, { useEffect } from 'react'
import { Card, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { getDevice, getUser } from '../../store/actions'
import { Secondary404 } from '../Page404'
import Device from '../lists/Device';
import Header from '../Header'
import Footer from '../Footer'
import Report from '../Report'



export default function Company() {

   const dispatch = useDispatch()

   const navigate = useNavigate()

   const { isAuthenticated, token } = useSelector(state => state?.authReducer)

   useEffect(() => {
      if (isAuthenticated) {
         dispatch(getDevice(token));
         dispatch(getUser(token));
      } else {
         navigate("/")
      }

   }, [isAuthenticated, token, dispatch])

   const { devices, loading, success, sending } = useSelector(state => state.companyReducer)

   useEffect(() => { document.body.className = "" }, [])

   return (
      <Container className="px-2 px-md-3" fluid>
         <Header />

         <Card className="p-3 p-md-4 p-lg-5 my-2">

            <Routes>
               <Route path="/report" element={<Report devices={devices} />} />
               <Route path="/device" element={<Device devices={devices} loading={loading} success={success} sending={sending} />} />
               <Route path="/*" element={<Secondary404 page="/company/report" />} />
            </Routes>

         </Card>

         <Footer />
      </Container>
   )
}