import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistReducer, persistStore } from 'redux-persist';
import thunk from "redux-thunk";
import promise from 'redux-promise-middleware'
import storage from 'redux-persist/lib/storage';
import authReducer from "./auth.reducer";
import adminReducer from "./admin.reducers";
import companyReducer from "./company.reducer";
import listReducer from "./list.reducer";



const persistConfig = { key: 'b2b', storage };

const reducer = combineReducers({ authReducer, adminReducer, companyReducer, listReducer });

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = createStore(persistedReducer, applyMiddleware(thunk, promise));

export let persistor = persistStore(store);