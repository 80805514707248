import React, { useState } from "react";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../store/actions'
import { BsBoxArrowRight, BsArrowLeftRight, BsPencilSquare, BsGear, BsPerson, BsSpeedometer2, BsShop, BsPhone, BsBoxArrowUpRight, BsCamera } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import Logo from "./Logo";
import CompanyForm from "./forms/CompanyForm";
import PhoneListSettings from "./forms/PhoneListSettings";
import PasswordChange from "./auth/PasswordChange";



export default function Header() {

	const navigate = useNavigate()

	const dispatch = useDispatch()

	const { token } = useSelector(state => state.authReducer)

	const { user, success } = useSelector(state => state.companyReducer)

	const [formShow, setFormShow] = useState(false);

	const [settingsShow, setSettingShow] = useState(false);

	const [passShow, setPassShow] = useState(false);

	const page = window.location.href.split("/")[3];

	return (
		<Navbar variant="dark">
			<Container className="justify-content-between align-items-end mt-3" fluid>

				<div className="d-flex align-items-center m-auto gap-2 display-5">
					<Logo />
				</div>

				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">

					<Nav className="align-items-center" id="nav">

						{page === "admin"
							? <>
								{/* <NavLink className="nav-link" to="report">
								<BsSpeedometer2 /> 
								<span className="nav-title">Özet</span>
								</NavLink> */}
								
								<NavLink className="nav-link" to="device">
									<BsPhone title="Cihazlar" />
									<span className="nav-title">Cihazlar</span>
								</NavLink>

								<NavLink className="nav-link" to="company">
									<BsShop title="Bayiler" />
									<span className="nav-title">Bayiler</span>
								</NavLink>
							</>
							: <>
								<NavLink className="nav-link" to="report">
									<BsSpeedometer2 title="Raporlar" />
									<span className="nav-title">Raporlar</span>
								</NavLink>

								<NavLink className="nav-link" to="device?filter=statusExcept&value=sold">
									<BsPhone title="Cihazlar" />
									<span className="nav-title">Cihazlar</span>
								</NavLink>

								<NavLink className="nav-link" to={"/" + token?.user?._id}>
									<BsBoxArrowUpRight title="Liste" />
									<span className="nav-title">Liste</span>
								</NavLink>

								<div className="vr" />

								<div className="company-name text-light d-inline-block text-truncate" style={{ maxWidth: "150px" }} >{token?.user?.company}</div>
							</>
						}

						<Dropdown className="d-inline">
							<Dropdown.Toggle size="sm" variant="link" className="text-light p-0 ps-2" title={token?.user?.company}>
								<FaUserCircle className="fs-3 user-icon" />
							</Dropdown.Toggle>

							<Dropdown.Menu align="end" id="settings">
								{token.roles?.includes("ADMIN") &&
									<>
										<Dropdown.Item className="menu-item" as="button" onClick={() => { navigate(page === "admin" ? "/company/report" : "/admin/device") }}>
											{page === "admin" ? <><BsPerson />Firma Paneli</> : <><BsSpeedometer2 />Yönetim Paneli</>}
										</Dropdown.Item>
										<Dropdown.Divider />
										<Dropdown.Item className="menu-item" href="https://shot.screenshotapi.net/screenshot?&url=https%3A%2F%2Fpoligsm.com%2F&full_page=true&output=image&file_type=webp&wait_for_event=load" target="_blank"><BsCamera />Ekran Görüntüsü</Dropdown.Item>
									</>
								}

								{page !== "admin" &&
									<>
										<Dropdown.Item className="menu-item" as="button" onClick={() => setFormShow(true)}><BsPencilSquare />Bilgileri Güncelle</Dropdown.Item>
										<Dropdown.Item className="menu-item" as="button" onClick={() => setSettingShow(true)}><BsGear />Liste Ayarı</Dropdown.Item>
										<Dropdown.Item className="menu-item" as="button" onClick={() => setPassShow(true)} id="change-password"><BsArrowLeftRight />Şifre Değiştir</Dropdown.Item>
										{/* <Dropdown.Item className="menu-item" ><BsFillPersonXFill />Hesabı Sil</Dropdown.Item> */}
										<Dropdown.Divider />
									</>
								}

								<Dropdown.Item className="menu-item" as="button" onClick={() => { dispatch(logout(token)); navigate("/"); }}><BsBoxArrowRight />Çıkış Yap</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>

					</Nav>

				</Navbar.Collapse>
			</Container>

			<CompanyForm show={formShow} onHide={() => setFormShow(false)} user={user} success={success} />

			<PhoneListSettings show={settingsShow} onHide={() => setSettingShow(false)} settings={user.settings} success={success} />

			<PasswordChange show={passShow} onHide={() => setPassShow(false)} />

		</Navbar >
	);
}