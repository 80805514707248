import React from 'react'
import { Modal } from 'react-bootstrap'
import { BsInfoCircle, BsShieldLock, BsSliders } from 'react-icons/bs'


export default function Terms(props) {
   return (

      <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>

         <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Hizmet Koşulları ve Özellikller</Modal.Title>
         </Modal.Header>

         <Modal.Body className='terms text-center'>

            <p className='fst-italic fs-6'>poligsm.com ürün takibi ve raporlanması için bilgi amaçlı kurulmuştur.</p>

            <h5 className='d-flex align-items-center justify-content-center display-6 gap-2 mt-4'><BsInfoCircle /> ÖZELLİKLER</h5>

            <ul className='text-start list-group list-group-flush'>
               <li className="list-group-item">Beş farklı türde cihazı <i>(telefon, tablet, saat, kulaklık, diğer)</i> ister masaüstü ister telefonunuzdan kolaylıkla ekleyebilirsiniz.</li>
               <li className="list-group-item">Cihazları marka, model, hafıza, renk ve liste fiyatı ekleyerek kayıt yapabilirsiniz.</li>
               <li className="list-group-item">Imei numarası <i>(isteğe bağlı)</i> girerek cihazlarınızı takip edebilirsiniz. <i>(İster tamamını ister son 4 hanesi yazabilirsiniz.)</i></li>
               <li className="list-group-item">Olası çalıntı cihaz durumunda cihazların geriye dönük <i>(eğer girmişseniz)</i> imeilerinden tespit edebilirsiniz.</li>
               <li className="list-group-item">Cihazın nereden alındığını ve nereye satıldığını <i>(isteğe bağlı)</i> girerek geriye dönük takip yapabilirsiniz.</li>
               <li className="list-group-item">Cihazları satışta, rezerve ve satıldı olarak işaretleyerek stok kontrolü yapabilirsiniz.</li>
               <li className="list-group-item">Cihazların alış ve satış fiyatını girerek kar/zarar durumunu kontrol edebilirsiniz.</li>
               <li className="list-group-item">Satıştaki cihazlarınızı satış listeleri yardımıyla hızlıca paylaşabilirsiniz.</li>
            </ul>

            <h5 className='d-flex align-items-center justify-content-center display-6 gap-2 mt-4'><BsSliders /> RAPORLAMA</h5>

            <ul className='text-start list-group list-group-flush'>
               <li className="list-group-item">Anlık stok durumunu takip edebilirsiniz.</li>
               <li className="list-group-item">Aşağıdaki başlıklarda raporları takip edebilirsiniz.</li>
               <ul className='text-start list-group list-group-flush'>
                  <li className="list-group-item">Stok Durumu (Telefon, Tablet, Saat, Kulaklık)</li>
                  <li className="list-group-item">Son Durum (Satışta, Rezerve, Satıldı) </li>
                  <li className="list-group-item">Günlük Kazanç (Bugün, Dün...)</li>
                  <li className="list-group-item">Aylık Kazanç (Eylül, Ağustos...) </li>
                  <li className="list-group-item">Günlük Alınan Cihaz  (Bugün, Dün...)</li>
                  <li className="list-group-item">Aylık Alınan Cihaz  (Eylül, Ağustos...) </li>
                  <li className="list-group-item">Telefon Marka Dağılımı (Apple, Samsung, Xiaomi, Diğer)</li>
                  <li className="list-group-item">Tarihe Göre Rapor (Alınan Cihaz, Satılan Cihaz, Kazanç)</li>
               </ul>
            </ul>

            <h5 className='d-flex align-items-center justify-content-center display-6 gap-2 mt-4'><BsShieldLock /> GÜVENLİK</h5>

            <ul className='text-start list-group list-group-flush'>
               <li className="list-group-item">Şifre bilgilerinizi yönetici dahil kimse göremez şifreli şekilde saklanmaktadır.</li>
               <li className="list-group-item">Her kullanıcı sadece kendi ürünlerini görmektedir.</li>
               <li className="list-group-item">Sitemiz ssl güvenlik sistemi ile korunmaktadır.</li>
            </ul>


         </Modal.Body>

         <Modal.Footer></Modal.Footer>

      </Modal >
   )
}