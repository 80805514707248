import React, { useEffect, useState } from 'react'
import { Card, Form, Button, Modal, Col, Row, Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux'
import { BsCheckLg } from 'react-icons/bs'
import { updateUser } from '../../store/actions';
import { colorPalette, iconList } from '../helpers/data'



export default function PhoneListSettings({ show, onHide, settings }) {

   const dispatch = useDispatch()

   const initialState = { icon: "BsWifi", logoName: { strong: "Poli", light: "Gsm" }, theme: "", description: "" }

   const { token } = useSelector(state => state.authReducer)

   const { success, sending } = useSelector(state => state.companyReducer)

   const [formData, setFormData] = useState(initialState)

   useEffect(() => setFormData(settings ? settings : initialState), [settings])

   const onInputChange = (e) => { setFormData({ ...formData, logoName: { ...formData.logoName, [e.target.name]: e.target.value } }) }

   const handleSubmit = (e) => {
      e.preventDefault();
      dispatch(updateUser(token, { "settings": formData }))
   }

   useEffect(() => success && onHide(), [success])


   return (
      <Modal show={show} onHide={onHide} size="md" className='modal-overflow' backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered >

         <Form onSubmit={handleSubmit}>

            <Modal.Header closeButton>
               <Modal.Title id="contained-modal-title-vcenter" style={{ paddingLeft: 0 }}>
                  Telefon Listesi Ayarı
               </Modal.Title>
            </Modal.Header>

            <Modal.Body className="m-md-3">

               <Form.Group controlId="preview" className="mb-4">
                  <Form.Label>Logo Önizleme</Form.Label>
                  <div className='display-3 text-center bg-light py-3 rounded-3 border'>
                     {iconList.map((item, i) => item.id === formData.icon && <span key={i}>{item.icon}</span>)}
                     <span> <b>{formData.logoName.strong}</b>{formData.logoName.light}</span>
                  </div>
               </Form.Group>

               <Form.Group controlId="personCompany" className="mb-3">
                  <Form.Label>Logo için firma adı (kısa)</Form.Label>
                  <Row>

                     <Col xs={4} >
                        <DropdownButton variant="link icon-select" size="lg" title={iconList.map((item, i) => item.id === formData.icon && <span key={i}>{item.icon}</span>)} disabled={sending}>
                           {iconList?.map((item, i) => <Dropdown.Item key={i} href="#" className='fs-4' onClick={() => setFormData({ ...formData, icon: item.id })} value={item.id}>{item.icon}</Dropdown.Item>)}
                        </DropdownButton>
                     </Col>

                     <Col xs={4} >
                        <Form.Control name="strong" type="text" maxLength="6" size='lg' placeholder='Kalın' onChange={onInputChange} value={formData.logoName.strong} disabled={sending} />
                     </Col>

                     <Col xs={4} >
                        <Form.Control name="light" type="text" maxLength="6" size='lg' placeholder='İnce' onChange={onInputChange} value={formData.logoName.light} disabled={sending} />
                     </Col>

                  </Row>
               </Form.Group>

               <Form.Group controlId="personDescription" className="mb-3">
                  <Form.Label>Açıklama</Form.Label>
                  <Form.Control name="description" type="text" maxLength="30" size='lg' placeholder='Cihazlarımız şehir içi kurye teslimatlıdır.' onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} value={formData.description} disabled={sending} />
               </Form.Group>

               <Form.Label>Tema Renk Seçimi</Form.Label>
               <Row>
                  {colorPalette.map((variant, i) => (
                     <Col xs={6} key={i}>
                        <Card className={variant.id + " mb-2"}>
                           <Card.Header className='color-picker' onClick={() => setFormData({ ...formData, theme: variant.id })} disabled={sending}>{variant.label} {formData.theme === variant.id && <BsCheckLg />}</Card.Header>
                        </Card>
                     </Col>
                  ))}
               </Row>
               
            </Modal.Body>


            {/* ------------------------------------ Button ------------------------------------ */}


            <Modal.Footer>

               <Button variant="outline-secondary from-btn" type="button" onClick={() => { onHide() }} disabled={sending} >Vazgeç</Button>

               <Button variant="primary from-btn" type="submit" disabled={sending} >Kaydet</Button>

            </Modal.Footer>

         </Form>
      </Modal >
   )
}
