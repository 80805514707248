import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button, Modal, Accordion, FloatingLabel } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux'
import { updateUsers, updateUser, delUsers } from '../../store/actions';
import { BsGear } from 'react-icons/bs';



export default function CompanyForm({ show, onHide, user, admin, success }) {

   const initialState = { _id: "", company: "", name: "", email: "", phone: "", address: "", options: { isBuyPrice: true, isBuyWho: true, isImei: true, isSoldPrice: true, isSoldWho: true, secondHand: true } }

   const dispatch = useDispatch()

   const [edit, setEdit] = useState(false)

   useEffect(() => setEdit(user && true), [user, show])

   const { token } = useSelector(state => state.authReducer)

   const { sending } = useSelector(state => state.adminReducer)

   const [formData, setFormData] = useState(initialState)

   useEffect(() => setFormData(user ? user : initialState), [user])

   const onInputChange = (e) => { setFormData({ ...formData, [e.target.name]: e.target.value }) }

   const onCheckChange = (e) => { setFormData({ ...formData, options: { ...formData.options, [e.target.name]: e.target.checked } }) }

   const handleSubmit = (e) => {
      e.preventDefault();

      if (admin) {
         dispatch(updateUsers(token, formData))

      } else {
         delete formData._id
         dispatch(updateUser(token, formData))
      }
   }

   useEffect(() => success && onHide(), [success])

   return (
      <Modal show={show} onHide={onHide} size="md" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered >

         <Form onSubmit={handleSubmit}>

            <Modal.Header closeButton>
               <Modal.Title id="contained-modal-title-vcenter" style={{ paddingLeft: 0 }}>
                  {user ? "Bayi Düzenle" : "Bayi Ekle"}
               </Modal.Title>
            </Modal.Header>

            <Modal.Body className="m-md-3">

               <Row className='align-items-end'>

                  <Col>
                     <FloatingLabel controlId="personCompany" label="Firma Adı" className="mb-4">
                        <Form.Control name="company" type="text" maxLength="20" size='lg' placeholder='Firma Adı' onChange={onInputChange} value={formData.company} disabled={edit || sending} required />
                     </FloatingLabel>

                     <FloatingLabel controlId="personName" label="Ad Soyad" className="mb-4">
                        <Form.Control name="name" type="text" maxLength="20" size='lg' placeholder='Ad Soyad' onChange={onInputChange} value={formData.name} disabled={edit || sending} required />
                     </FloatingLabel>

                     <FloatingLabel controlId="personEmail" label="Email" className="mb-4">
                        <Form.Control name="email" type="email" maxLength="30" size='lg' placeholder='Email' onChange={onInputChange} value={formData.email} disabled={edit || sending} required />
                     </FloatingLabel>

                     <FloatingLabel controlId="personPhone" label="Telefon Numarası" className="mb-4">
                        <Form.Control name="phone" type="tel" pattern="[0-9]{11}" size='lg' placeholder='Telefon Numarası' onChange={onInputChange} value={formData.phone} disabled={edit || sending} required />
                     </FloatingLabel>

                     <FloatingLabel controlId="personAddress" label="Adres">
                        <Form.Control name="address" type="text" maxLength="30" size='lg' placeholder='Adres' onChange={onInputChange} value={formData.address} disabled={edit || sending} />
                     </FloatingLabel>

                  </Col>

                  {admin && <Accordion className='mt-4'>
                     <Accordion.Item eventKey="0">
                        <Accordion.Header><BsGear className='me-1' /> Firma Yetkileri</Accordion.Header>
                        <Accordion.Body className='p-2'>
                           <Row>

                              <Form.Group as={Col} xs="6" controlId="isImei" className="mb-3">
                                 <Form.Check name="isImei" type="switch" onChange={onCheckChange} checked={formData.options.isImei} label="Imei" disabled={edit || sending} />
                              </Form.Group>

                              <Form.Group as={Col} xs="6" controlId="isBuyPrice" className="mb-3">
                                 <Form.Check name="isBuyPrice" type="switch" onChange={onCheckChange} checked={formData.options.isBuyPrice} label="Alış Fiyatı" disabled={edit || sending} />
                              </Form.Group>

                              <Form.Group as={Col} xs="6" controlId="isBuyWho" className="mb-3">
                                 <Form.Check name="isBuyWho" type="switch" onChange={onCheckChange} checked={formData.options.isBuyWho} label="Kimden Alındı" disabled={edit || sending} />
                              </Form.Group>

                              <Form.Group as={Col} xs="6" controlId="isSoldPrice" className="mb-3">
                                 <Form.Check name="isSoldPrice" type="switch" onChange={onCheckChange} checked={formData.options.isSoldPrice} label="Satış Fiyatı" disabled={edit || sending} />
                              </Form.Group>

                              <Form.Group as={Col} xs="6" controlId="isSoldWho" className="mb-3">
                                 <Form.Check name="isSoldWho" type="switch" onChange={onCheckChange} checked={formData.options.isSoldWho} label="Kime Satıldı" disabled={edit || sending} />
                              </Form.Group>

                              <Form.Group as={Col} xs="6" controlId="secondHand" className="mb-3">
                                 <Form.Check name="secondHand" type="switch" onChange={onCheckChange} checked={formData.options.secondHand} label="2.El Giriş" disabled={edit || sending} />
                              </Form.Group>

                              {/* <Form.Group as={Col} xs="6" controlId="isDelete" className="mb-3">
                                 <Form.Check name="deleted" type="switch" onChange={onCheckChange} checked={formData.options.deleted} label="Firmayı Kaldır" disabled={edit || sending} />
                              </Form.Group> */}

                           </Row>

                        </Accordion.Body>
                     </Accordion.Item>

                  </Accordion>}

               </Row>

            </Modal.Body>


            {/* ------------------------------------ Button ------------------------------------ */}


            <Modal.Footer>

               <Button variant="outline-secondary from-btn" type="button" onClick={() => { setFormData(user ? user : initialState); onHide() }} disabled={sending}>Vazgeç</Button>

               {/* <Button variant="outline-danger from-btn" type="button" onClick={() => dispatch(delUsers(token, user._id))} style={{ display: (!user || edit) && "none" }}>Sil</Button> */}

               {edit && <Button variant="outline-warning from-btn" type="button" onClick={() => setEdit(false)} disabled={sending}>Düzenle</Button>}

               {!edit && <Button variant="outline-primary from-btn" type="submit" disabled={edit || sending}>Kaydet</Button>}

            </Modal.Footer>

         </Form>
      </Modal >
   )
}