import React, { useState, useEffect } from 'react'
import { Card, Form, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { BsPencilSquare, BsPerson, BsTelephone, BsEnvelope, BsGeoAlt, BsChatRightText } from 'react-icons/bs'
import { getUsers } from '../../store/actions';
import { Spinner } from "../Spinner";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { useMediaQuery } from 'react-responsive'
import { formatPhoneNumber } from '../helpers/phoneNumber';
import CompanyForm from '../forms/CompanyForm';



export default function CompanyList() {

   const dispatch = useDispatch()

   const { users, loading, success } = useSelector(state => state.adminReducer)

   const { isAuthenticated, token } = useSelector(state => state.authReducer)

   const [detailShow, setDetailShow] = useState(false);

   const [user, setUser] = useState(null);

   useEffect(() => { isAuthenticated && dispatch(getUsers(token)); }, [isAuthenticated, token, dispatch])


   const data = [...users.filter(user => user._id !== "62bb735a5ed386b4fdbc93ae")] //System admin hide

   const filterValue = [
      { name: 'company', operator: 'contains', type: 'string', value: '' },
      { name: 'name', operator: 'contains', type: 'string', value: '' },
      { name: 'email', operator: 'contains', type: 'string', value: '' },
      { name: 'phone', operator: 'contains', type: 'string', value: '' },
      { name: 'address', operator: 'contains', type: 'string', value: '' },
   ];

   const columns = [
      { name: 'company', header: 'Firma', minWidth: 50, defaultFlex: 3, style: { fontWeight: 'bold' } },
      { name: 'name', header: 'Yetkili', minWidth: 50, defaultFlex: 4 },
      { name: 'email', header: 'Email', minWidth: 50, defaultFlex: 3 },
      {
         name: 'phone', header: 'Telefon', minWidth: 50, defaultFlex: 3, style: { fontWeight: 'bold' },
         render: ({ value }) => <a href={`tel:${value}`} className="text-link">{formatPhoneNumber(value)}</a>
      },
      { name: 'address', header: 'Adres', minWidth: 50, defaultFlex: 3 },
      {
         name: '_id', header: '', minWidth: 50, defaultFlex: 1, sortable: false, textAlign: 'center',
         render: ({ data }) => <button className='btn btn-link d-flex p-1 fs-4 text-secondary'
            onClick={(e) => { e.stopPropagation(); setUser(data); setDetailShow(true) }}><BsPencilSquare /></button>
      }
   ];

   const [filter, setFilter] = useState("");

   const handleFilter = (e) => { setFilter(e.target.value) }

   const filteredUser = data.filter(user => (
      user.company.toLowerCase().includes(filter.toLowerCase())
      || user.name.toLowerCase().includes(filter.toLowerCase())
      || user.phone.includes(filter.toLowerCase())
      || user.email.toLowerCase().includes(filter.toLowerCase())
      || user.address.toLowerCase().includes(filter.toLowerCase())
   ))


   return (
      <>

         <h1 className="display-5 text-title mb-3">Bayi Listesi</h1>

         <Form.Group controlId="mainFilter" className="mb-3">
            <Form.Control name="search" size='lg' type="search" className="bg-light" placeholder='Arama...' onChange={handleFilter} value={filter} autoComplete="off" />
         </Form.Group>

         {useMediaQuery({ minWidth: 768 })
            ? <ReactDataGrid
               idProperty="id"
               columns={columns}
               dataSource={filteredUser}
               style={{ minHeight: "calc(100vh - 380px)" }}
               defaultFilterValue={filterValue}
               columnUserSelect={true}
               showActiveRowIndicator={true}
               enableColumnFilterContextMenu={false}
               showColumnMenuTool={false}
               showEmptyRows={true}
               emptyText="Kayıt Bulunamadı"
               showCellBorders={"vertical"}
               pagination
               defaultLimit={100}
            // enableSelection={true}
            />
            : filteredUser.map((item, i) =>
               <Card bg="warning" className="mb-3 w-100" key={i} style={{ display: "inline-block" }}>
                  <Card.Header className="text-center fw-bold p-1 pointer" onClick={() => { setUser(item); setDetailShow(true) }}>{item.company}</Card.Header>

                  <ListGroup variant="flush">
                     <ListGroup.Item><BsPerson className='me-1' /> {item.name}</ListGroup.Item>
                     <ListGroup.Item><BsTelephone className='me-1' /> <a href={`tel:${item.phone}`} className="text-link">{item.phone}</a></ListGroup.Item>
                     <ListGroup.Item><BsEnvelope className='me-1' /> {item.email}</ListGroup.Item>
                     <ListGroup.Item><BsGeoAlt className='me-1' /> {item.address}</ListGroup.Item>
                     <ListGroup.Item><BsChatRightText className='me-1' /> {item.description}</ListGroup.Item>
                  </ListGroup>

               </Card>
            )}

         <CompanyForm show={detailShow} onHide={() => setDetailShow(false)} user={user} admin="admin" success={success} />

         {!users?.length > 0 && loading && <Spinner />}

      </>
   )
}