import React, { useEffect } from 'react'
import { Card, Container } from 'react-bootstrap'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getDevices } from "../../store/actions";
import { Secondary404 } from '../Page404'
import Header from '../Header'
import Device from '../lists/Device'
import CompanyList from '../lists/CompanyList'
import Footer from '../Footer'
import Report from '../Report'


export default function Admin() {

   const dispatch = useDispatch()

   const navigate = useNavigate()

   const { isAuthenticated, token } = useSelector(state => state?.authReducer)

   const { devices, loading, success, sending } = useSelector(state => state.adminReducer);

   useEffect(() => { (isAuthenticated || token?.roles?.includes("ADMIN")) ? dispatch(getDevices(token)) : navigate("/") }, [isAuthenticated, success, token, dispatch])

   useEffect(() => { document.body.className = "" }, [])

   return (
      <Container className="px-2 px-md-3" fluid>
         <Header />

         <Card className="p-3 p-md-4 p-lg-5 my-2">

            <Routes>
               <Route path="/report" element={<Report devices={devices} />} />
               <Route path="/device" element={<Device devices={devices} loading={loading} success={success} sending={sending} />} />
               <Route path="/company" element={<CompanyList />} />
               <Route path="/*" element={<Secondary404 page="/admin/device" />} />
            </Routes>

         </Card>

         <Footer />
      </Container>
   )
}