import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './components/lists/HomePage';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
// import PasswordReset from './components/auth/PasswordReset';
import PasswordChange from './components/auth/PasswordChange';
import Admin from './components/users/Admin';
import Company from './components/users/Company';
import { Primary404 } from './components/Page404';
import '@inovua/reactdatagrid-community/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';



export default function App() {
  return (
    <BrowserRouter>

      <Routes>

        <Route path="/" element={<HomePage />} />
        <Route path="/:id" element={<HomePage />} />

        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        {/* <Route path="reset-password" element={<PasswordReset />} /> */}
        <Route path="change-password" element={<PasswordChange />} />

        <Route path="admin/*" element={<Admin />} />
        <Route path="company/*" element={<Company />} />

        <Route path="*" element={<Primary404 />} />
        <Route path="page404" element={<Primary404 />} />

      </Routes>

    </BrowserRouter>
  );
}