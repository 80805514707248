export function Spinner() {
   return (
      <div className="loading"><div className="spinner"></div></div>
   )
}


export function Loader() {
   return (
      <div className="loader">Loading...</div>
   )
}