import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button, Modal, ButtonGroup, ToggleButton, FloatingLabel } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux'
import { putDevice, postDevice, delDevice, putDevices, delDevices, clearMessage } from '../../store/actions';
import { brands, storage, color, status, type, watchSize, watchBrand, tabletBrand, headsetBrand } from "../helpers/data"
import { Rating } from 'react-simple-star-rating'
// import { isValidImei } from "../helpers/imei"



export default function DeviceForm({ show, onHide, devicestate, company, success, sending }) {

   const dispatch = useDispatch()

   const { token } = useSelector(state => state?.authReducer)

   const initialState = { deviceType: "phone", brand: "", newDevice: "0", buyPrice: "", buyWho: "", color: "", imei: "", serial: "", model: "", storage: "", watchSize: "", price: "", soldPrice: "", soldWho: "", warranty: "", cosmetic: null, appleBattery: "", status: "open", listed: "close", soldDate: null, reserveDate: null, userId: token.userId }

   const [formData, setFormData] = useState(initialState)

   const isAdmin = token.roles?.includes("ADMIN")

   const options = token?.user?.options

   useEffect(() => dispatch(clearMessage()), [])

   useEffect(() => setFormData(devicestate ? { ...devicestate } : initialState), [devicestate])

   const onInputChange = (e) => { setFormData({ ...formData, [e.target.name]: e.target.value }) }

   const handleSubmit = (e) => {
      e.preventDefault();

      // if (formData.imei.length > 0 ? isValidImei(formData.imei) && formData.imei.length === 15 && (!devicestate ? !devices.some(device => device.imei === formData.imei) : true) : true) {

      delete formData.user

      if (devicestate) {
         dispatch(company ? putDevices(token, formData) : putDevice(token, formData))
      } else {
         dispatch(postDevice(token, formData))
      }
      // setImei(initialImei)
      // }
   }

   useEffect(() => success && (onHide(), setFormData(initialState)), [success])

   useEffect(() => { setFormData({ ...formData, soldDate: formData.status === "sold" ? Date.now() : null, reservedDate: formData.status === "reserved" ? Date.now() : null }) }, [formData.status])


   // const initialImei = { check: false, length: false, isValid: false, unique: false, validated: false }
   // const { devices } = useSelector(state => company ? state?.adminReducer : state?.companyReducer) 
   // const [imei, setImei] = useState(initialImei)
   // useEffect(() => setImei(initialImei), [show])


   return (
      <Modal show={show} onHide={onHide} size="md" backdrop="static" aria-labelledby="contained-modal-title-vcenter" className='modal-padding modal-overflow' centered >

         <Form onSubmit={handleSubmit}>

            <Modal.Header closeButton>
               <Modal.Title id="contained-modal-title-vcenter">
                  {devicestate ? "Cihaz Düzenle" : "Cihaz Ekle"}
               </Modal.Title>
            </Modal.Header>

            <Modal.Body className="m-md-2">

               <Row className='align-items-end'>

                  {company && devicestate && <>
                     <Form.Group controlId="deviceCompany" className="mb-3">
                        <Form.Label>Firma</Form.Label>
                        <div>{company.company} - <span className='text-muted'>{company.address}</span> </div>
                        <a href={"tel:" + company.phone} className="text-link">{company.phone}</a>
                     </Form.Group>
                     <hr />
                  </>}

                  <Col xs="6">
                     <FloatingLabel controlId="deviceType" label="Cihaz Türü" className="mb-3">
                        <Form.Select name="deviceType" size='lg' onChange={onInputChange} value={formData.deviceType} disabled={sending || company} required>
                           <option value="" hidden>Cihaz Türü seçiniz... *</option>
                           {type?.map((item, i) => <option key={i} value={item.id}>{item.label}</option>)}
                        </Form.Select>
                     </FloatingLabel>
                  </Col>

                  {options?.secondHand && <Form.Group as={Col} xs="6" className="mb-3" >
                     <ButtonGroup size='lg' className='d-flex'>
                        <ToggleButton name="newDevice" type="radio" id="newDevice-1" variant="outline-warning px-4" onChange={onInputChange} checked={"0" === formData.newDevice} value="0" disabled={sending || company}> Sıfır </ToggleButton>
                        <ToggleButton name="newDevice" type="radio" id="newDevice-2" variant="outline-danger px-4" onChange={onInputChange} checked={"2" === formData.newDevice} value="2" disabled={sending || company}> 2.El </ToggleButton>
                     </ButtonGroup>
                  </Form.Group>}

                  <hr />

                  {formData.deviceType !== "other" && <Col sm="6">
                     <FloatingLabel controlId="deviceBrand" label="Marka*" className="mb-3" >
                        <Form.Select name="brand" size='lg' onChange={onInputChange} value={formData.brand} disabled={sending || company} required>
                           <option value="" hidden>Marka seçiniz... *</option>
                           {formData.deviceType === "phone" && brands?.map((item, i) => <option key={i} value={item}>{item}</option>)}
                           {formData.deviceType === "tablet" && tabletBrand?.map((item, i) => <option key={i} value={item}>{item}</option>)}
                           {formData.deviceType === "watch" && watchBrand?.map((item, i) => <option key={i} value={item}>{item}</option>)}
                           {formData.deviceType === "headset" && headsetBrand?.map((item, i) => <option key={i} value={item}>{item}</option>)}
                        </Form.Select>
                     </FloatingLabel>
                  </Col>}

                  {formData.deviceType === "other" && <Col sm="6">
                     <FloatingLabel controlId="deviceModel" label='Marka*' className="mb-3">
                        <Form.Control name="brand" size='lg' type="text" maxLength="20" placeholder='Marka*' onChange={onInputChange} value={formData.brand} disabled={sending || company} required />
                     </FloatingLabel>
                  </Col>}

                  <Col sm="6">
                     <FloatingLabel controlId="deviceModel" label='Model*' className="mb-3">
                        <Form.Control name="model" size='lg' type="text" maxLength="20" placeholder='Model*' onChange={onInputChange} value={formData.model} disabled={sending || company} required />
                     </FloatingLabel>
                  </Col>

                  {["phone", "tablet"].includes(formData.deviceType) && <Col xs="6">
                     <FloatingLabel controlId="deviceStorage" label='Hafıza*' className="mb-3">
                        <Form.Select name="storage" size='lg' onChange={onInputChange} value={formData.storage} disabled={sending || company} required>
                           <option value="" hidden>Hafıza seçiniz...*</option>
                           {storage?.map((item, i) => <option key={i} value={item}>{item}</option>)}
                        </Form.Select>
                     </FloatingLabel>
                  </Col>}

                  {formData.deviceType === "watch" && <Col xs="6">
                     <FloatingLabel controlId="watchSize" label='Saat Kordonu*' className="mb-3">
                        <Form.Select name="watchSize" size='lg' onChange={onInputChange} value={formData.watchSize} disabled={sending || company} required>
                           <option value="" hidden>Boyut seçiniz...*</option>
                           {watchSize?.map((item, i) => <option key={i} value={item}>{item}</option>)}
                        </Form.Select>
                     </FloatingLabel>
                  </Col>}

                  <Col xs={["headset", "other"].includes(formData.deviceType) ? "12" : "6"}>
                     <FloatingLabel controlId="deviceColor" label='Renk*' className="mb-3">
                        <Form.Select name="color" size='lg' onChange={onInputChange} value={formData.color} disabled={sending || company} required>
                           <option value="" hidden>Renk seçiniz...*</option>
                           {color?.map((item, i) => <option key={i} value={item}>{item}</option>)}
                        </Form.Select>
                     </FloatingLabel>
                  </Col>

                  {options?.isImei && ["phone", "tablet"].includes(formData.deviceType) && <Col sm="6">
                     <FloatingLabel controlId="deviceImei" label='Imei no' className="mb-3">
                        <Form.Control name="imei" size='lg' type="text" max="15" placeholder='Imei no' pattern='[0-9]{0,15}' title='En fazla 15 hane rakam girebilirsiniz' onChange={onInputChange} value={formData.imei} disabled={sending || company} />
                     </FloatingLabel>
                  </Col>}

                  {/* {options?.isImei && ["phone", "tablet"].includes(formData.deviceType) && <Col sm="6">
                     <FloatingLabel controlId="deviceImei" label='Imei no' className="mb-3">
                        <Form.Control name="imei" size='lg' type="number" max="999999999999999" placeholder='Imei no'
                           className={imei.check ?
                              (isValidImei(formData.imei)
                                 && formData.imei.length === 15
                                 && (!devicestate ? !devices.some(device => device.imei === formData.imei) : true)
                                 ? " is-valid"
                                 : " is-invalid")
                              : ""}
                           onChange={onInputChange} value={formData.imei} disabled={sending || company} />
                        <span className={`text-danger text-end w-100 small position-absolute` + (imei.check
                           ? (isValidImei(formData.imei)
                              && formData.imei.length === 15
                              && (!devicestate ? !devices.some(device => device.imei === formData.imei) : true)
                              ? " d-none"
                              : " d-block")
                           : " d-none")}
                           style={{ bottom: "-17px" }}>
                           {!devices.some(device => device.imei === formData.imei) ? "Geçersiz imei no" : "Kayıtlı imei no"}
                        </span>
                     </FloatingLabel>
                  </Col>} */}

                  <Col sm="6">
                     <FloatingLabel controlId="deviceSerial" label='Seri No' className="mb-3">
                        <Form.Control name="serial" size='lg' type="text" maxLength="20" placeholder='Seri No place' onChange={onInputChange} value={formData.serial} disabled={sending || company} />
                     </FloatingLabel>
                  </Col>

                  <Col sm="6">
                     <FloatingLabel controlId="devicePrice" label='Liste Fiyatı*' className="mb-3">
                        <Form.Control name="price" size='lg' type="number" min="0" max="100000" placeholder='max 100000' onChange={onInputChange} value={formData.price} disabled={sending || company} required />
                     </FloatingLabel>
                  </Col>

                  {options?.isBuyWho && <Col sm="6">
                     <FloatingLabel controlId="deviceBuyWho" label='Kimden Alındı' className="mb-3">
                        <Form.Control name="buyWho" size='lg' type="text" maxLength="20" placeholder='Kimden Alındı' onChange={onInputChange} value={formData.buyWho} disabled={sending || company} />
                     </FloatingLabel>
                  </Col>}

                  {options?.isBuyPrice && <Col sm="6">
                     <FloatingLabel controlId="deviceBuyPrice" label='Alış Fiyatı' className="mb-3">
                        <Form.Control name="buyPrice" size='lg' type="number" min="0" max="100000" placeholder='Alış Fiyatı' onChange={onInputChange} value={formData.buyPrice} disabled={sending || company} />
                     </FloatingLabel>
                  </Col>}

                  {formData.newDevice === "2" && <Col xs="6">
                     <FloatingLabel controlId="deviceWarranty" label='Garanti (Ay)' className="mb-3">
                        <Form.Control name="warranty" size='lg' type="string" maxLength="20" placeholder='Garanti Süresi' onChange={onInputChange} value={formData.warranty} disabled={sending || company} />
                     </FloatingLabel>
                  </Col>}

                  {(formData.brand === "Apple" && formData.newDevice === "2") && <Col xs="6">
                     <FloatingLabel controlId="deviceBattery" label='Pil Sağlığı (%)' className="mb-3">
                        <Form.Control name="battery" size='lg' type="number" min="0" max="100" placeholder='Apple Pil Sağlığı (%)' onChange={onInputChange} value={formData.battery} disabled={sending || company} />
                     </FloatingLabel>
                  </Col>}

                  {formData.newDevice === "2" &&
                     <Form.Group as={Col} sm="12" controlId="deviceCosmetic" className="mb-3" >
                        <div className='cosmetic'>
                           <div className='cosmetic-label'>Kozmetik </div>
                           <Rating onClick={(e) => setFormData({ ...formData, cosmetic: e })}
                              fillColorArray={['#f17a45', '#f17a45', '#f19745', '#f19745', '#f1a545', '#f1a545', '#f1b345', '#f1b345', '#f1d045', '#f1d045']}
                              tooltipArray={['Çok Kötü', 'Çok Kötü +', 'Kötü', 'Kötü +', 'Ortalama', 'Ortalama +', 'İyi', 'İyi +', 'Çok iyi', 'Çok iyi +']}
                              ratingValue={formData.cosmetic}
                              allowHover={false}
                              allowHalfIcon
                              showTooltip
                              disabled={sending || company} />
                        </div>
                     </Form.Group>}

                  {(options?.isSoldWho && formData.status === "sold") && <Col sm="6">
                     <FloatingLabel controlId="deviceSoldWho" label='Kime Satıldı?' className="mb-3">
                        <Form.Control name="soldWho" size='lg' type="text" maxLength="20" placeholder='Kime Satıldı?' onChange={onInputChange} value={formData.soldWho} disabled={sending || company} />
                     </FloatingLabel>
                  </Col>}

                  {(options?.isSoldPrice && formData.status === "sold") && <Col sm="6">
                     <FloatingLabel controlId="deviceSoldPrice" label='Satış Fiyatı' className="mb-3">
                        <Form.Control name="soldPrice" size='lg' type="number" min="0" max="100000" placeholder='Satış Fiyatı' onChange={onInputChange} value={formData.soldPrice} disabled={sending || company} />
                     </FloatingLabel>
                  </Col>}

                  <ButtonGroup size="lg" className='me-auto'>
                     {status.map((item, i) => <ToggleButton key={i} name="status" type="radio" id={`status-${i}`} variant={`outline-${item.class}`}
                        onChange={onInputChange} checked={item.id === formData.status} value={item.id} disabled={sending || company}>
                        {item.label}
                     </ToggleButton>)}
                  </ButtonGroup>

               </Row>

            </Modal.Body>


            {/* ------------------------------------ Button ------------------------------------ */}


            <Modal.Footer className='flex-nowrap'>

               {isAdmin && formData.status !== "sold" && <Form.Group controlId="deviceListed" className="me-auto ps-2">
                  <Form.Check name="listed" type="switch" label="Listele"
                     onChange={(e) => {
                        dispatch(company
                           ? putDevices(token, { id: formData.id, listed: e.target.checked === "open" ? "close" : "open" })
                           : putDevice(token, { id: formData.id, listed: e.target.checked === "open" ? "close" : "open" }))
                     }}
                     checked={formData.listed === "open" ? true : false} disabled={sending} />
               </Form.Group>}

               <Button variant="outline-secondary" type="button"
                  onClick={() => { setFormData(devicestate ? devicestate : initialState); onHide() }} disabled={sending}>Vazgeç</Button>

               {!company &&
                  <>
                     {devicestate && <Button variant="outline-danger" type="button"
                        onClick={() => dispatch(company ? delDevices(token, devicestate.id) : delDevice(token, devicestate.id))} disabled={sending}>Sil</Button>}

                     <Button variant="primary" type="submit"
                        // onClick={() => setImei({ ...imei, check: formData.imei.length > 0 })} 
                        disabled={sending}>Kaydet</Button>
                  </>
               }

            </Modal.Footer>

         </Form>
      </Modal >
   )
}