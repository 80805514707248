import React, { useState, useEffect, useCallback } from 'react'
import { Col, Button, Form, Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BsPlusLg, BsPencilSquare, BsFillCircleFill, BsFunnel, BsBox, BsXLg, BsBroadcast, BsDot } from 'react-icons/bs'
import { putDevice, putDevices } from '../../store/actions';
import { status, type, list } from "../helpers/data"
import { useMediaQuery } from 'react-responsive'
import ReactDataGrid from '@inovua/reactdatagrid-community';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DeviceForm from '../forms/DeviceForm';
import MobilView from './MobilView';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import { Spinner } from "../Spinner";
import moment from 'moment'
import 'moment/locale/tr';
window.moment = moment;



export default function Device({ devices, loading, success, sending }) {

   const navigate = useNavigate()
   const dispatch = useDispatch()
   const { token } = useSelector(state => state.authReducer)
   const [company, setCompany] = useState("");
   const [id, setId] = useState("");
   const matches = useMediaQuery({ minWidth: 768 })
   const isAdmin = token.roles?.includes("ADMIN")


   // #region --------------------------------- Report Filter ---------------------------------

   const url = new URL(window.location.href);
   const params = new URLSearchParams(url.search.substring(1));
   const filterType = params.get("filter") && params.get("filter").split(" ");
   const filterValue = params.get("value") && params.get("value").split(" ");
   const page = url.pathname.split("/")[1]

   // #endregion



   // #region --------------------------------- ReactDataGrid Column and Filter ---------------------------------

   const filterValueType = [
      { name: 'brand', operator: 'contains', type: 'string', value: '' },
      { name: 'deviceType', operator: 'contains', type: 'string', value: '' },
      { name: 'model', operator: 'contains', type: 'string', value: '' },
      { name: 'storage', operator: 'contains', type: 'string', value: '' },
      { name: 'watchSize', operator: 'contains', type: 'string', value: '' },
      { name: 'color', operator: 'contains', type: 'string', value: '' },
      { name: 'imei', operator: 'contains', type: 'string', value: 0 },
      { name: 'serial', operator: 'contains', type: 'string', value: 0 },
      { name: 'price', operator: 'gte', type: 'number', value: 0 },
      { name: 'buyPrice', operator: 'contains', type: 'string', value: '' },
      { name: 'soldPrice', operator: 'contains', type: 'string', value: '' },
      { name: 'createDate', operator: 'eq', type: 'date', value: '' },
      { name: 'soldDate', operator: 'eq', type: 'date', value: '' },
      { name: 'listed', operator: 'inlist', type: 'select', value: null },
      { name: 'status', operator: 'inlist', type: 'select', value: null },
   ];


   const columns = [
      {
         name: 'user.company', header: 'Firma', minWidth: 60, defaultFlex: 2, headerAlign: 'center', editable: false, defaultVisible: (isAdmin && page === "admin"),
         render: ({ data }) => data?.user?.company,
      },
      {
         name: 'deviceType', header: <BsBox />, minWidth: 50, defaultFlex: 1, textAlign: 'center', filterEditor: SelectFilter, filterEditorProps: { dataSource: type }, editable: false,
         render: ({ value }) => (type.map((item, i) => item.id === value && <span key={i} style={{ fontSize: "1.2rem", color: item.color }}>{item.icon}</span>))
      },
      { name: 'brand', header: 'Marka', minWidth: 60, defaultFlex: 3, headerAlign: 'center', editable: false },
      { name: 'model', header: 'Model', minWidth: 75, defaultFlex: 5, headerAlign: 'center', style: { fontWeight: 'bold' } },
      { name: 'storage', header: 'Hafıza', minWidth: 60, defaultFlex: 2, textAlign: 'center', editable: false },
      { name: 'watchSize', header: 'Saat', minWidth: 60, defaultFlex: 2, textAlign: 'center', editable: false },
      { name: 'color', header: 'Renk', minWidth: 60, defaultFlex: 2, textAlign: 'center', editable: false },
      {
         name: 'imei', header: 'Imei', minWidth: 60, defaultFlex: 2, textAlign: 'center', editable: false,
         render: ({ value }) => (value.substr(value.length - 4, 4))
      },
      { name: 'serial', header: 'Seri No', minWidth: 60, defaultFlex: 2, textAlign: 'center', editable: false },
      {
         name: 'price', header: 'Liste Fiyatı', minWidth: 75, defaultFlex: 2, headerAlign: 'center', textAlign: 'end', type: 'number', filterEditor: NumberFilter, style: { fontWeight: 'bold' },
         render: ({ value }) => ("₺" + value?.toLocaleString()),
      },
      {
         name: 'buyPrice', header: 'Alış Fiyatı', minWidth: 75, defaultFlex: 2, headerAlign: 'center', textAlign: 'end', type: 'number', filterEditor: NumberFilter,
         render: ({ value }) => ("₺" + value?.toLocaleString())
      },
      {
         name: 'soldPrice', header: 'Satış Fiyatı', minWidth: 75, defaultFlex: 2, headerAlign: 'center', textAlign: 'end', type: 'number', filterEditor: NumberFilter, style: { fontWeight: 'bold' },
         render: ({ value }) => ("₺" + value?.toLocaleString())
      },
      {
         name: 'createDate', header: 'Kayıt', minWidth: 60, defaultFlex: 3, textAlign: 'center', dateFormat: 'DD.MM.YYYY', filterEditor: DateFilter, editable: false,
         filterEditorProps: (props, { index }) => {
            return { dateFormat: 'DD.MM.YYYY', placeholder: index === 1 ? 'Created date is before...' : 'Created date is after...' }
         },
         render: ({ value, cellProps: { dateFormat } }) => moment(value).format(dateFormat),
      },
      {
         name: 'soldDate', header: 'Satış', minWidth: 60, defaultFlex: 3, textAlign: 'center', dateFormat: 'DD.MM.YYYY', filterEditor: DateFilter, editable: false,
         filterEditorProps: (props, { index }) => {
            return { dateFormat: 'DD.MM.YYYY', placeholder: index === 1 ? 'Created date is before...' : 'Created date is after...' }
         },
         render: ({ value, cellProps: { dateFormat } }) => value && moment(value).format(dateFormat),
      },
      {
         name: 'listed', header: <BsBroadcast />, minWidth: 50, defaultFlex: 1, textAlign: 'center', filterEditor: SelectFilter, filterEditorProps: { dataSource: list }, editable: false, defaultVisible: isAdmin,
         render: ({ data, value }) => <button className='btn btn-link text-secondary fs-5 p-2' aria-label="listed-button"
            onClick={(e) => {
               e.stopPropagation();
               setId(data.id);
               dispatch(page === "admin"
                  ? putDevices(token, { id: data.id, listed: value === "open" ? "close" : "open" })
                  : putDevice(token, { id: data.id, listed: value === "open" ? "close" : "open" })
               )
            }}>{sending && data.id === id ? <div className='listing'></div> : value === "open" ? <BsBroadcast /> : <BsDot />}</button>
      },
      {
         name: 'status', header: 'Durum', minWidth: 60, defaultFlex: 2, textAlign: 'center', filterEditor: SelectFilter, filterEditorProps: { dataSource: status }, editable: false,
         render: ({ value }) => (status.map((item, i) => item.id === value && <BsFillCircleFill key={i} className={"text-" + item.class} />))
      },
      {
         name: 'id', header: <BsPencilSquare />, minWidth: 50, defaultFlex: 1, headerAlign: 'center', editable: false, sortable: false,
         render: ({ data }) => <button className='btn btn-link d-flex text-secondary p-1 fs-4' aria-label="edit-button"
            onClick={(e) => { e.stopPropagation(); setDeviceState(data); setCompany(isAdmin ? data?.user : ""); setDetailShow(true); }} ><BsPencilSquare /></button>
      }
   ];

   // #endregion



   // #region --------------------------------- Filter Fonk ---------------------------------

   const initialFilter = { search: "", deviceType: "", brand: "", newDevice: "", color: "", model: "", storage: "", listed: "", status: "", statusExcept: "", soldDate: "", createDate: "", soldMonth: "", buyMonth: "", soldStartDate: "", soldEndDate: "", buyStartDate: "", buyEndDate: "" };

   const [filter, setFilter] = useState(initialFilter);

   const momentFonk = (date) => moment(date).format('YYYY-MM-DD')

   const momentMonth = (date) => moment(date).format('YYYY-MM')

   const dateFilter = devices.filter(device => (
      filter.soldDate
         ? momentFonk(device.soldDate) === momentFonk(filter.soldDate)
         : filter.createDate
            ? momentFonk(device.createDate) === momentFonk(filter.createDate)
            : filter.soldMonth
               ? momentMonth(device.soldDate) === momentMonth(filter.soldMonth)
               : filter.buyMonth
                  ? momentMonth(device.createDate) === momentMonth(filter.buyMonth)
                  : filter.soldStartDate
                     ? momentFonk(device.soldDate) >= momentFonk(filter.soldStartDate) && momentFonk(device.soldDate) <= momentFonk(filter.soldEndDate)
                     : filter.buyStartDate
                        ? momentFonk(device.createDate) >= momentFonk(filter.buyStartDate) && momentFonk(device.createDate) <= momentFonk(filter.buyEndDate)
                        : device
   ))

   const devicesFilter = dateFilter.filter(device => (
      device.deviceType.includes(filter.deviceType)
      && device.brand.includes(filter.brand)
      && device.storage.includes(filter.storage)
      && device.color.includes(filter.color)
      && device.status.includes(filter.status)
      && device.status !== filter.statusExcept
      && (device.model.toLowerCase().includes(filter.search.toLowerCase())
         || device.brand.toLowerCase().includes(filter.search.toLowerCase())
         || device.storage.toLowerCase().includes(filter.search.toLowerCase())
         || device.color.toLowerCase().includes(filter.search.toLowerCase())
         || device.imei.toLowerCase().includes(filter.search.toLowerCase())
         || device?.user?.company.toLowerCase().includes(filter.search.toLowerCase())
         || device.price === parseInt(filter.search)
      )
   ))

   const handleFilter = (e) => { setFilter({ ...filter, [e.target.name]: e.target.value }) }

   useEffect(() => { setFilter(initialFilter) }, [matches])

   useEffect(() => { filterType && setFilter({ ...filter, [filterType[0]]: filterValue[0], [filterType[1]]: filterValue[1], [filterType[2]]: filterValue[2] }) }, [])

   // #endregion



   // #region --------------------------------- Storage list created by filtered devices ---------------------------------

   const filterStorage = [...new Set(devices.map(d => d.storage))].sort((a, b) => parseInt(a.slice(0, - 3)) - parseInt(b.slice(0, - 3)))

   filterStorage[0] === "1 TB" && filterStorage.push(filterStorage.shift())

   // #endregion



   // #region --------------------------------- DeviceForm Modal ---------------------------------

   const [detailShow, setDetailShow] = useState(false);

   const [deviceState, setDeviceState] = useState(null);

   const handleDevices = (id) => {
      setDeviceState(devices.find(device => device.id === id));
      isAdmin && setCompany(devices.find(device => device.id === id).user)
      setDetailShow(true)
   }

   // #endregion



   // #region --------------------------------- ReactDataGrid inline edit fonk ---------------------------------

   const onEditComplete = useCallback(({ value, columnId, rowId }) => { dispatch(putDevice(token, { id: rowId, [columnId]: value })) }, [devicesFilter])

   // #endregion




   return (
      <>
         <div className='d-flex align-items-end justify-content-between mb-3'>
            <span className="display-5 text-title">Cihaz Listesi</span>

            <span className='d-flex gap-2'>
               {matches && filterType && <Button variant="outline-danger" onClick={() => { setFilter(initialFilter); navigate("") }}><BsXLg /> Temizle</Button>}
               <Button variant="success" onClick={() => { setDetailShow(true); setDeviceState(null) }}><BsPlusLg /> Cihaz Ekle</Button>
            </span>

         </div>

         {matches
            ? <>
               <Form.Group controlId="mainFilter" className="mb-3">
                  <Form.Control name="search" size='lg' type="search" className="bg-light" placeholder='Arama...' onChange={handleFilter} value={filter.search} autoComplete="off" />
               </Form.Group>

               <ReactDataGrid
                  idProperty="id"
                  columns={columns}
                  dataSource={devicesFilter}
                  style={{ minHeight: "calc(100vh - 320px)" }}
                  defaultFilterValue={filterValueType}
                  columnUserSelect={true}
                  showActiveRowIndicator={true}
                  enableColumnFilterContextMenu={false}
                  showColumnMenuTool={false}
                  showEmptyRows={true}
                  emptyText="Kayıt Bulunamadı"
                  pagination
                  defaultLimit={100}
                  showCellBorders={"vertical"}
                  onEditComplete={onEditComplete}
                  editable={true}
               // enableSelection={true}
               />
            </>
            : <>
               <Accordion className='mb-3'>
                  <Accordion.Item eventKey="0">
                     <Accordion.Header><BsFunnel className='me-1' />Filtre</Accordion.Header>
                     <Accordion.Body>

                        <Form.Group controlId="deviceType" className="mb-3">
                           <Form.Select name="deviceType" onChange={handleFilter} value={filter.deviceType}>
                              <option value="" hidden className='text-muted'>Cihaz türü seçiniz...</option>
                              <option value="" >Tümü</option>
                              {type?.map((item, i) => <option key={i} value={item.id}>{item.label}</option>)}
                           </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="deviceBrand" className="mb-3">
                           <Form.Select name="brand" onChange={handleFilter} value={filter.brand}>
                              <option value="" hidden className='text-muted'>Marka seçiniz...</option>
                              {[...new Set(devices.map(d => d.brand))].sort()?.map((item, i) => <option key={i} value={item}>{item}</option>)}
                           </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="deviceStorage" className="mb-3">
                           <Form.Select name="storage" onChange={handleFilter} value={filter.storage}>
                              <option value="" hidden>Hafıza seçiniz...</option>
                              {filterStorage?.map((item, i) => <option key={i} value={item}>{item}</option>)}
                           </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="deviceColor" className="mb-3">
                           <Form.Select name="color" onChange={handleFilter} value={filter.color}>
                              <option value="" hidden>Renk seçiniz...</option>
                              {[...new Set(devices.map(d => d.color))].sort()?.map((item, i) => <option key={i} value={item}>{item}</option>)}
                           </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="deviceStatus" className="mb-3">
                           <Form.Select name="status" onChange={handleFilter} value={filter.status}>
                              <option value="" hidden>Cihaz durumu...</option>
                              {status?.map((item, i) => <option key={i} value={item.id}>{item.label}</option>)}
                           </Form.Select>
                        </Form.Group>

                        {isAdmin && <Form.Group controlId="deviceListed" className="mb-3">
                           <Form.Select name="listed" onChange={handleFilter} value={filter.listed}>
                              <option value="" hidden>Telefon listesi...</option>
                              <option value="" >Tümü</option>
                              {list?.map((item, i) => <option key={i} value={item.id}>{item.label}</option>)}
                           </Form.Select>
                        </Form.Group>}

                        <Col xs="12" className="text-end">
                           <Button variant="outline-danger" onClick={() => { setFilter(initialFilter); navigate("") }}><BsXLg /> Temizle</Button>
                        </Col>

                     </Accordion.Body>
                  </Accordion.Item>

               </Accordion>

               <Form.Group controlId="mainFilter" className="mb-3">
                  <Form.Control name="search" size='lg' type="search" className="bg-light" placeholder='Arama...' onChange={handleFilter} value={filter.search} autoComplete="off" />
               </Form.Group>

               <MobilView devices={devicesFilter} handleDevices={handleDevices} isAdmin={isAdmin} />
            </>
         }

         <DeviceForm show={detailShow} onHide={() => setDetailShow(false)} devicestate={deviceState} success={success} sending={sending} company={company} />

         {!devices?.length > 0 && loading && <Spinner />}

      </>
   )
}