import React from 'react'
import { Container } from 'react-bootstrap'
import { FaTwitter, FaYoutube, FaEnvelope } from 'react-icons/fa'



export default function Footer() {

   const today = new Date();
   const year = today.getFullYear();

   return (
      <Container className="d-flex flex-wrap justify-content-between mb-3" fluid>

         <div className="d-flex align-items-center text-muted small gap-2">
            &copy; {year} poligsm.com
         </div>

         <div className="d-flex gap-3">
            <a href="#" aria-label="Twitter" role="button" target="_blank" rel="noreferrer">
               <FaTwitter className='fs-5 text-muted' />
            </a>
            <a href="#" aria-label="Youtube" role="button" target="_blank" rel="noreferrer">
               <FaYoutube className='fs-4 text-muted' />
            </a>
            <a href="#" aria-label="Email" role="button" target="_blank" rel="noreferrer">
               <FaEnvelope className='fs-5 text-muted' />
            </a>
         </div>

      </Container>
   )
}