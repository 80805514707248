import React, { useState, useEffect } from "react";
import { Card, Form, Button, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { BsFillPersonPlusFill } from "react-icons/bs";
import { register, clear } from "../../store/actions";
import Terms from "./Terms";
import { Loader } from '../Spinner';
import Background from './Background';




const initialState = { company: "", name: "", email: "", phone: "", password: "", address: "" }

const initialValid = { valid: false, passwordConfirm: "", terms: false }


export default function Register() {

  const dispatch = useDispatch()

  useEffect(() => { dispatch(clear()) }, [])

  const navigate = useNavigate()

  const { message, error, sending, success } = useSelector(state => state.authReducer)

  const [validated, setValidated] = useState(initialValid);

  const [formData, setFormData] = useState(initialState)

  const [modalShow, setModalShow] = useState(false);

  const onInputChange = (e) => { setFormData({ ...formData, [e.target.name]: e.target.value }) }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.company && formData.name && formData.password === validated.passwordConfirm && formData.phone.length === 11) {

      dispatch(register(formData))
    }
  };

  useEffect(() => { success && setTimeout(() => { navigate("/") }, 1500) }, [success])


  return (

    <div className="auth-wrapper">

      <div className="auth-content">

        <Background />

        <Card>
          <Card.Body className="text-center p-4">

            <div className="my-3">
              <BsFillPersonPlusFill className='fs-1 auth-svg' />
            </div>

            <h3 className="mb-4">Kayıt Formu</h3>

            <Form onSubmit={handleSubmit}>

              <Form.Group controlId="personCompany" className="mb-3">
                <Form.Control name="company" type="text" maxLength="20" placeholder="Firma Adı" size='lg'
                  className={`bg-light py-2` + (validated.valid ? (formData.company ? " is-valid" : " is-invalid") : "")}
                  onChange={onInputChange} value={formData.company} disabled={sending || success} required autoFocus />
              </Form.Group>

              <Form.Group controlId="personName" className="mb-3">
                <Form.Control name="name" type="text" maxLength="20" placeholder="Ad Soyad" size='lg'
                  className={`bg-light py-2` + (validated.valid ? (formData.name ? " is-valid" : " is-invalid") : "")}
                  onChange={onInputChange} value={formData.name} disabled={sending || success} required />
              </Form.Group>

              <Form.Group controlId="personAddress" className="mb-3">
                <Form.Control name="address" type="text" maxLength="30" placeholder="Adres" size='lg'
                  className={`bg-light py-2` + (validated.valid ? (formData.address ? " is-valid" : " is-invalid") : "")}
                  onChange={onInputChange} value={formData.address} disabled={sending || success} required />
              </Form.Group>

              <Form.Group controlId="personEmail" className="mb-3">
                <Form.Control name="email" type="email" maxLength="30" placeholder="Email" size='lg'
                  className={`bg-light py-2` + (validated.valid ? (formData.email.includes("@") ? " is-valid" : " is-invalid") : "")}
                  onChange={onInputChange} value={formData.email} disabled={sending || success} required />
              </Form.Group>

              <Form.Group controlId="personPhone" className="mb-3">
                <Form.Control name="phone" type="tel" pattern="[0-9]{11}" placeholder="Telefon Numarası" size='lg'
                  className={`bg-light py-2` + (validated.valid ? (formData.phone.length === 11 ? " is-valid" : " is-invalid") : "")}
                  onChange={onInputChange} value={formData.phone} disabled={sending || success} required />
              </Form.Group>

              <Form.Group controlId="personPassword" className="mb-3">
                <Form.Control name="password" type="password" minLength="6" maxLength="20" placeholder="Şifre" size='lg'
                  className={`bg-light py-2` + (validated.valid ? ((formData.password === validated.passwordConfirm) && formData.password !== "" ? " is-valid" : " is-invalid") : "")}
                  onChange={onInputChange} value={formData.password} disabled={sending || success} required />
              </Form.Group>

              <Form.Group controlId="personRePassword" className="mb-3">
                <Form.Control name="passwordConfirm" type="password" minLength="6" maxLength="20" placeholder="Şifre Tekrar" size='lg'
                  className={`bg-light py-2` + (validated.valid ? ((formData.password === validated.passwordConfirm) && validated.passwordConfirm !== "" ? " is-valid" : " is-invalid") : "")}
                  onChange={(e) => setValidated({ ...validated, passwordConfirm: e.target.value })} value={validated.passwordConfirm} disabled={sending || success} required />
              </Form.Group>

              <Form.Check type="checkbox" id="personCheckbox" className="text-start mb-4">
                <Form.Check.Input type="checkbox"
                  className={`text-start mb-4` + (validated.valid ? (validated.terms ? " is-valid" : " is-invalid") : "")}
                  onChange={(e) => setValidated({ ...validated, terms: e.target.checked })} checked={validated.terms} disabled={sending || success} required />
                <Form.Check.Label className="fw-normal is-invalid"><Link to="#" onClick={() => setModalShow(true)}>Hizmet koşullarını</Link> kabul ediyorum.</Form.Check.Label>
              </Form.Check>


              {message
                ? <Alert variant="success" className="small mt-2">{message}</Alert>
                : error
                  ? <Alert variant="danger" className="small mt-2">{error}</Alert>
                  : <Button variant="primary" type="submit" className="mb-4 px-4 mx-auto" onClick={() => setValidated({ ...validated, valid: true })} disabled={sending || success} >{sending ? <Loader /> : "Kayıt Ol"}</Button>}

            </Form>

            <p className="mb-2"><Link to="/login" className="text-muted small">Hesabın var mı?</Link></p>

          </Card.Body>
        </Card>

        <Terms show={modalShow} onHide={() => setModalShow(false)} />

      </div>
    </div >
  );
}