import {
   BsTabletLandscape, BsSmartwatch, BsHeadphones, BsPhone, BsBox,
   BsWifi, BsCollectionPlay,
   BsCloudArrowDown,
   BsCloudArrowUp,
   BsCloudCheck,
   BsCloudDownload,
   BsCloudFog2,
   BsCollection,
   BsCloudLightning,
   BsEject,
   BsFileEarmarkBarGraph
} from 'react-icons/bs';



export const colorPalette = [
   { id: 'card-primary', label: "Mavi", class: "header-primary" },
   { id: 'card-secondary', label: "Mor", class: "header-secondary" },
   { id: 'card-success', label: "Yeşil", class: "header-success" },
   { id: 'card-danger', label: "Kırmızı", class: "header-danger" },
   { id: 'card-info', label: "Turkuaz", class: "header-info" },
   { id: 'card-light', label: "Gri", class: "header-light" },
   { id: 'card-dark', label: "Siyah", class: "header-dark" },
   { id: 'card-pink', label: "Pembe", class: "header-pink" }
]

export const iconList = [
   { id: 'BsWifi', icon: <BsWifi /> },
   { id: "BsCollectionPlay", icon: <BsCollectionPlay /> },
   { id: "BsCloudArrowDown", icon: <BsCloudArrowDown /> },
   { id: "BsCloudArrowUp", icon: <BsCloudArrowUp /> },
   { id: "BsCloudCheck", icon: <BsCloudCheck /> },
   { id: "BsCloudDownload", icon: <BsCloudDownload /> },
   { id: "BsCloudFog2", icon: <BsCloudFog2 /> },
   { id: "BsCollection", icon: <BsCollection /> },
   { id: "BsCloudLightning", icon: <BsCloudLightning /> },
   { id: "BsEject", icon: <BsEject /> },
   { id: "BsFileEarmarkBarGraph", icon: <BsFileEarmarkBarGraph /> },
]


export const storage = ['', '16 GB', '32 GB', '64 GB', '128 GB', '256 GB', '512 GB', '1 TB']

export const color = ["", "Altın", "Bakır", "Bej", "Beyaz", "Bordo", "Bronz", "Gri", "Gümüş", "Kahverengi", "Kırmızı", "Lacivert", "Mavi", "Mor", "Pembe", "Sarı", "Siyah", "Turuncu", "Yeşil",]

export const type = [
   { id: "phone", label: "Telefon", color: "#000", icon: <BsPhone /> },
   { id: "tablet", label: "Tablet", color: "#0D6EFD", icon: <BsTabletLandscape /> },
   { id: "watch", label: "Saat", color: "#DC3545", icon: <BsSmartwatch /> },
   { id: "headset", label: "Kulaklık", color: "#198754", icon: <BsHeadphones /> },
   { id: "other", label: "Diğer", color: "#9ba7b4", icon: <BsBox /> },
]


export const status = [
   { id: 'open', label: "Satışta", class: "success" },
   { id: 'reserved', label: "Rezerve", class: "warning" },
   { id: 'sold', label: "Satıldı", class: "danger" },
]

export const list = [
   { id: "open", label: "Açık" },
   { id: "close", label: "Kapalı" },
]

export const tabletBrand = ["", "Technopc", "Samsung", "Huawei", "Everest", "Lenovo", "Ipad", "Xiaomi", "Redway", "Reeder", "Quadro"]

export const watchBrand = ["", "Xiaomi", "Huawei", "Samsung", "Apple", "Oppo", "Amazfit", "Honor", "Haylou"]

export const headsetBrand = ["", "Apple", "Asus", "Energy Sistem", "Gamepower", "Happy Plugs", "Huawei", "Hytech", "Lenovo", "Samsung", "Sony"]

export const watchSize = ["", "38mm", "39mm", "40mm", "41mm", "42mm", "43mm", "44mm", "45mm", "46mm", "47mm", "48mm", "49mm", "50mm"]

export const brands = [
   "",
   "Apple",
   "Samsung",
   "Xiaomi",
   "Huawei",
   "Alcatel",
   "Anka",
   "BB Mobile",
   "BlackBerry",
   "Casper",
   "Doogee",
   "Elephone",
   "General Mobile",
   "Hiking",
   "HONOR",
   "HTC",
   "Infinix",
   "LG",
   "Meizu",
   "Nokia",
   "Omix",
   "Oneplus",
   "Oppo",
   "Oukitel",
   "Philips",
   "POCO",
   "Realme",
   "Reeder",
   "TCL",
   "Tecno",
   "Trident",
   "Ulefone",
   "Vestel",
   "vivo",
   "ZTE"
]