import { Button, Container } from 'react-bootstrap';
import Background from './auth/Background';
import { useNavigate } from "react-router-dom";


export function Primary404() {

  const navigate = useNavigate();

  return (
    <div className="auth-wrapper">
      <div className="auth-content back-404">

        <Background />

        <h1 className="title-404">404</h1>
        <h2 className="text-muted mb-4">Page not found</h2>
        <Button variant="outline-secondary" className="align-self-center mx-auto mb-5" onClick={() => navigate("/")}> Back to Home</Button>

      </div>
    </div>
  );
}


export function Secondary404({ page }) {

  const navigate = useNavigate();

  return (
    <Container className="page-404">

      <h1 className='title-404'>404</h1>
      <p className='fw-bold text-muted'>Seems there is nothing here</p>
      <Button variant="outline-secondary" className="align-self-center mx-auto mb-5" onClick={() => navigate(page)}> Back to Home</Button>

    </Container>
  )
}