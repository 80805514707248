import React from 'react'
import { BsWifi } from 'react-icons/bs'


export default function Logo() {
   return (
      <>
         <BsWifi className='text-light' />
         <span className='text-light'> <b>Poli</b>Gsm</span>
      </>
   )
}