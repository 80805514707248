import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Card } from 'react-bootstrap';
import { login } from '../../store/actions'
import { FaRegEye, FaRegEyeSlash, FaUnlock } from "react-icons/fa";
import Background from './Background';
import { Loader } from '../Spinner';




export default function Login() {

   const dispatch = useDispatch()

   const navigate = useNavigate();

   const { isAuthenticated, error, sending } = useSelector(state => state.authReducer)

   const [showPassword, setShowPassword] = useState(false);

   const [formData, setFormData] = useState({ email: "", password: "" })

   useEffect(() => { isAuthenticated && navigate("/company/report") }, [isAuthenticated])

   const onInputChange = (e) => { setFormData({ ...formData, [e.target.name]: e.target.value }) }

   const handleSubmit = (e) => { e.preventDefault(); dispatch(login(formData)) };


   return (

      <div className="auth-wrapper">
         <div className="auth-content">

            <Background />

            <Card>

               <Card.Body className="text-center p-4">

                  <div className="my-4">
                     <FaUnlock className='fs-1 auth-svg' />
                  </div>

                  <h3 className="mb-4">Giriş</h3>

                  <Form onSubmit={handleSubmit}>

                     <Form.Group className="mb-3" controlId="email">
                        <Form.Control name="email" type="email" maxLength="30" placeholder="Email" className="bg-light py-2" size='lg' onChange={onInputChange} value={formData.email} disabled={sending} required autoFocus/>
                     </Form.Group>

                     <Form.Group className="mb-4 show-relative" controlId="password">
                        <span className='show-password text-muted' onClick={() => setShowPassword(!showPassword)} >{showPassword ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                        <Form.Control name="password" type={showPassword ? "text" : "password"} maxLength="20" placeholder="Şifre" className="bg-light py-2" size='lg' onChange={onInputChange} value={formData.password} disabled={sending} required />
                     </Form.Group>

                     {error && <div className="text-danger mb-1">{error}</div>}

                     <Button variant="primary" type='submit' className="mb-4 px-4 mx-auto" disabled={sending} >{sending ? <Loader /> : "Giriş Yap"}</Button>

                  </Form>

                  {/* <p className="mb-1"><Link to="/reset-password" className="text-muted small">Şifremi unuttum</Link></p> */}
                  <p className="mb-1"><Link to="/register" className="text-muted small">Hesabınız yok mu?</Link></p>
                  <p className="mb-4"><Link to="/" className="text-muted small">Anasayfa</Link></p>

               </Card.Body>
            </Card>

         </div>
      </div>
   )
}