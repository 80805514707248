import { useEffect } from "react";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceList } from "../../store/actions";
import { Alert, Card, ListGroup, Col, Container, Row, Button, Nav } from "react-bootstrap";
import { formatPhoneNumber } from "../helpers/phoneNumber"
import { BsFillShareFill, BsLock, BsFillPersonPlusFill, BsHouse } from "react-icons/bs";
import { colorPalette, iconList, type } from '../helpers/data'
import { RWebShare } from "react-web-share";
import Logo from "../Logo";
import Footer from '../Footer'
import moment from 'moment';
import 'moment/locale/tr';



export default function HomePage() {

   const param = useParams();

   const dispatch = useDispatch();

   const navigate = useNavigate()

   useEffect(() => { (param?.id?.length === 24 || param?.id?.length === undefined) ? dispatch(getDeviceList(param.id)) : navigate("/page404") }, [param, dispatch]);

   const { user, devices } = useSelector(state => state?.listReducer);

   const isAuthenticated = useSelector(state => state?.authReducer?.isAuthenticated)

   useEffect(() => { document.body.className = colorPalette.map(item => item.id === user?.settings?.theme ? item?.class : "").join("") }, [user])

   return (<>

      <Container className="phone-page" fluid>

         <div className="d-flex align-items-center justify-content-between text-light banner px-3 py-2">

            <RWebShare data={{ text: user?.company, url: window.location.href, title: "PoliGSM", }} onClick={() => console.log()}>
               <Button variant="link" className="nav-link" title="Paylaş"><BsFillShareFill /> Paylaş</Button>
            </RWebShare>

            <Nav className="navbar-nav d-flex flex-row align-items-center">
               {isAuthenticated
                  ? <NavLink className="nav-link" to="/company/report"><BsHouse /> Anasayfa</NavLink>
                  : <>
                     <NavLink className="nav-link" to="/register"><BsFillPersonPlusFill /> Kayıt</NavLink>
                     <NavLink className="nav-link" to="/login"><BsLock /> Giriş</NavLink>
                  </>}
            </Nav>

         </div>


         <Row className="text-center text-light mt-md-0 mb-3">

            <Col md={6} className="d-flex flex-column justify-content-center gap-2 my-3">

               <div className="d-flex align-items-center justify-content-center gap-2 display-2">
                  {user?.settings?.icon
                     ? <>
                        {iconList.map((item, i) => item.id === user?.settings?.icon && <span key={i}>{item.icon}</span>)}
                        <span>
                           <b>{user?.settings?.logoName.strong}</b>{user?.settings?.logoName.light}
                        </span>
                     </>
                     : <Logo />
                  }
               </div>

               <span className="lead">{moment().format("D MMM YYYY dddd")}</span>

            </Col>

            <Col md={5} className="d-flex flex-column align-items-center justify-content-center">

               <div className="d-flex gap-5 mb-2">
                  <div className="fs-4">
                     <span className="d-inline-block text-truncate" style={{ maxWidth: "250px" }} title={user?.name}>{user?.name}</span><br />
                     <a href={"tel:+9" + user?.phone} className="text-light text-decoration-none">{formatPhoneNumber(user?.phone)}</a>
                  </div>

               </div>

               <span className="d-inline-block fst-italic text-truncate" style={{ maxWidth: "400px" }}>{user?.settings?.description}</span>

            </Col>

         </Row>


         {/* ---------------------------------- list ----------------------------------- */}


         <div className='card-columns mt-3'>
            {devices?.map((item, i) =>
               <Card className={(user?.settings?.theme ? user?.settings?.theme : "card-info") + " mb-3 w-100"} key={i} style={{ display: "inline-block" }}>
                  <Card.Header className="text-center fw-bold p-1">{item?.brand}</Card.Header>
                  <ListGroup variant="flush">
                     {item?.devices?.map((elem, i) =>
                        elem && <ListGroup.Item key={i} className="d-flex align-items-center p-2">

                           <div className="d-flex justify-content-center gap-1 me-auto">
                              {elem?.model} {elem?.watchSize ? elem?.watchSize : elem?.storage} {elem?.color}
                           </div>

                           {type.map((item, i) => item.id === elem.deviceType && item.id !== "phone" && <span key={i} className='text-muted'>{item.icon}</span>)}

                           <Alert variant="text-dark bg-light border" className="fw-bold p-0 px-1 ms-2 m-0">₺{elem?.price.toLocaleString()}</Alert>
                        </ListGroup.Item>
                     )}
                  </ListGroup>
               </Card>
            )}
         </div>

      </Container >

      <Footer />

   </>
   );
}