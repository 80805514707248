import React, { useState, useEffect } from 'react'
import { Card, Col, Row, Table, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { type, status } from './helpers/data';
import { BsCalendarDate, BsCalendar3, BsInfoSquare, BsBarChartLine, BsCoin, BsPhone, BsGraphUp, BsSave, BsCart2, BsFillCircleFill, BsCircle, BsPieChart, BsRecord, BsCalendar3Range } from 'react-icons/bs';
import alasql from 'alasql';
import moment from 'moment';
import 'moment/locale/tr';



export default function Report({ devices }) {

	const navigate = useNavigate()

	alasql.fn.moment = moment;

	const formatter = new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', maximumFractionDigits: 0 });

	const [date, setDate] = useState({ from: moment(new Date()).add(-7, "days").format('YYYY-MM-DD'), to: moment(new Date()).format('YYYY-MM-DD') });

	const [profitDate, setprofitDate] = useState("");

	useEffect(() => { fnProfitDate(date.from, date.to) }, [date])

	const handleDate = (e) => { setDate({ ...date, [e.target.name]: moment(e.target.value).format('YYYY-MM-DD') }) }



	const fnProfitDate = (from, to) => {
		const soldDev = alasql(`
			SELECT SUM(soldPrice) - SUM(buyPrice) AS profit, count(*) AS soldDevice 
			FROM ? 
			WHERE status = "sold" AND moment(soldDate).format("YYYY-MM-DD") >= '${from}' AND moment(soldDate).format("YYYY-MM-DD") <= '${to}'
  		`, [devices]);

		const buyDev = alasql(`
			SELECT count(*) AS buyDevice
			FROM ? 
			WHERE moment(createDate).format("YYYY-MM-DD") >= '${from}' AND moment(createDate).format("YYYY-MM-DD") <= '${to}'
		`, [devices]);

		setprofitDate({ ...soldDev[0], ...buyDev[0] });
	}


	const allDeviceBrandCount = alasql(/*sql*/`
		WITH tablo AS (
			SELECT brand title, count(*) [count], ROWNUM() [row]
			FROM ? 
			WHERE [status] IN ("open", "reserved") AND deviceType = "phone" 
			GROUP BY brand
			ORDER BY [count] DESC
		)
		SELECT * 
		FROM tablo WHERE [row] <= 4
			union all CORRESPONDING
		SELECT 'Diğer' title, SUM([count]) [count], 0 [row] FROM tablo WHERE [row] > 4
  `, [devices]);


	const allDeviceStatusCount = alasql(/*sql*/`
		SELECT [status] title, count(*) [count] 
		FROM ?  
		GROUP BY [status]
	`, [devices]).sort((a, b) => a.title.localeCompare(b.title));


	const allDevicePrice = alasql(/*sql*/`
		SELECT SUM(price) AS price 
		FROM ? 
		WHERE [status] IN ("open", "reserved")
	`, [devices])[0].price;


	const allDeviceTypeCount = alasql(`
		SELECT deviceType title, count(*) [count], SUM(price) AS price 
		FROM ? 
		WHERE [status] IN ("open", "reserved") 
		GROUP BY deviceType
	`, [devices]).sort((a, b) => a.title === "phone" ? -1 : 0);


	const profitExpected = alasql(`
		SELECT SUM(price) - SUM(buyPrice) AS profit 
		FROM ? 
		WHERE [status] IN ("open", "reserved")
	`, [devices])[0].profit;


	const profitSum = alasql(`
		SELECT SUM(soldPrice) - SUM(buyPrice) AS profit 
		FROM ? 
		WHERE status = "sold"
	`, [devices])[0].profit;


	const profitDaily = alasql(`
		SELECT *
		FROM 
			(
				SELECT moment(soldDate).format("YYYY-MM-DD") day, SUM(soldPrice) - SUM(buyPrice) AS profit, count(*) AS [count] 
				FROM ? 
				WHERE status = "sold" 
				GROUP BY moment(soldDate).format("YYYY-MM-DD")
			) tablo
			ORDER BY day DESC 
		LIMIT 5
  `, [devices]);


	const profitMounthly = alasql(`
		SELECT *
		FROM
			(
				SELECT moment(soldDate).format("YYYY-MM") mount, SUM(soldPrice) - SUM(buyPrice) AS profit, count(*) AS [count] 
				FROM ? 
				WHERE status = "sold" 
				GROUP BY moment(soldDate).format("YYYY-MM")
			)
		ORDER BY mount DESC 
		LIMIT 5
	`, [devices]);


	const buyDaily = alasql(`
		SELECT *
		FROM
			(
				SELECT moment(createDate).format("YYYY-MM-DD") day, count(*) AS [count] 
				FROM ? 
				GROUP BY moment(createDate).format("YYYY-MM-DD")
			)
		ORDER BY day DESC 
		LIMIT 5
	`, [devices]);


	const buyMounthly = alasql(`
		SELECT *
		FROM
			(
				SELECT moment(createDate).format("YYYY-MM") mount, count(*) AS [count] 
				FROM ? 
				GROUP BY moment(createDate).format("YYYY-MM")
			)
		ORDER BY mount DESC 
		LIMIT 5
	`, [devices]);




	return (
		<>
			<h1 className="display-5 text-title mb-4">Raporlar</h1>

			<Row className="g-4">


				{/* ------------------------------------ Stok Durumu ------------------------------------ */}


				<Col sm={12} md={6} lg={4}>
					<Card className='alert alert-warning card-report'>

						<Card.Title><BsInfoSquare /> Stok Durumu</Card.Title>
						<hr />

						<Table variant='warning' hover>
							<tbody className="fs-5">
								{allDeviceTypeCount.map((item, i) => (<tr key={i}>
									{type.map((elem, m) => elem.id === item.title &&
										<td key={m} onClick={() => navigate(`/company/device?filter=deviceType+statusExcept&value=${item.title}+sold`)}>
											<span className='table-item'>{elem.icon}{elem.label}</span>
										</td>
									)}
									<td className='table-count'>{item.count}</td>
									<td className='table-count'>{formatter.format(item.price)}</td>
								</tr>
								))}
							</tbody>
						</Table>

					</Card>
				</Col>


				{/* ------------------------------------ Stoktaki Ürünler ------------------------------------ */}


				<Col sm={12} md={6} lg={4}>
					<Card className='alert alert-light border card-report'>

						<Card.Title><BsBarChartLine /> Stoktaki Ürünler</Card.Title>
						<hr />

						<Table hover>
							<tbody className="fs-5 pe-none">
								<tr>
									<td>
										<span className='table-item'><BsPhone />Toplam Cihaz</span>
									</td>
									<td className='table-count'>{allDeviceTypeCount.reduce((a, b) => a + b.count, 0)}</td>
								</tr>
								<tr>
									<td>
										<span className='table-item'><BsCoin />Toplam Fiyat</span>
									</td>
									<td className='table-count'>{formatter.format(allDevicePrice)}</td>
								</tr>
								<tr>
									<td>
										<span className='table-item'><BsGraphUp />Beklenen Kazanç</span>
									</td>
									<td className='table-count'>{formatter.format(profitExpected)}</td>
								</tr>
								<tr>
									<td>
										<span className='table-item'><BsSave />Toplam Kazanç</span>
									</td>
									<td className='table-count'>{formatter.format(profitSum)}</td>
								</tr>
							</tbody>
						</Table>

					</Card>
				</Col>


				{/* ------------------------------------ Son Durum ------------------------------------ */}


				<Col sm={12} md={6} lg={4}>
					<Card className='alert alert-warning card-report'>

						<Card.Title><BsCircle /> Son Durum</Card.Title>
						<hr />
						<Table variant='warning' hover>
							<tbody className="fs-5">
								{allDeviceStatusCount.map((item, i) => (
									<tr key={i}>
										<td onClick={() => navigate(`/company/device?filter=status&value=${item.title}`)}>
											{status.map(elem => elem.id === item.title &&
												<span className='table-item' key={i}><BsFillCircleFill className={"text-" + elem.class} />{elem.label}</span>)}
										</td>
										<td className='table-count'>{item.count}</td>
									</tr>
								))}
								<tr>
									<td onClick={() => navigate(`/company/device`)}>
										<span className='table-item'><BsFillCircleFill />Toplam</span>
									</td>
									<td className='table-count'>{allDeviceStatusCount.reduce((a, b) => a + b.count, 0)}</td>
								</tr>
							</tbody>
						</Table>

					</Card>
				</Col>


				{/* ------------------------------------ Günlük Kazanç ------------------------------------ */}


				<Col sm={12} md={6} lg={4}>
					<Card className='alert alert-light border card-report'>

						<Card.Title><BsSave /> Günlük Kazanç</Card.Title>
						<hr />
						<Table hover>
							<tbody className="fs-5">

								{profitDaily.map((item, i) => (
									<tr key={i}>
										<td onClick={() => navigate(`/company/device?filter=soldDate&value=${item.day}`)}>
											<span className='table-item'>
												<BsCalendarDate />
												{item.day === moment(new Date()).format("YYYY-MM-DD")
													? "Bugün"
													: item.day === moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD")
														? "Dün"
														: moment(item.day).format("DD MMM - ddd")}
											</span>
										</td>
										<td className='table-count'>{item.count}</td>
										<td className='table-count'>{formatter.format(item.profit)}</td>
									</tr>
								))}

							</tbody>
						</Table>

					</Card>
				</Col>


				{/* ------------------------------------ Aylık Kazanç ------------------------------------ */}


				<Col sm={12} md={6} lg={4}>
					<Card className='alert alert-warning card-report'>

						<Card.Title><BsSave /> Aylık Kazanç</Card.Title>
						<hr />
						<Table variant='warning' hover>
							<tbody className="fs-5">
								{profitMounthly.map((item, i) => (
									<tr key={i}>
										<td onClick={() => navigate(`/company/device?filter=soldMonth&value=${item.mount}`)}>
											<span className='table-item'><BsCalendar3 /> {moment(item.mount).format("MMMM")}</span>
										</td>
										<td className='table-count'>{item.count}</td>
										<td className='table-count'>{formatter.format(item.profit)}</td>
									</tr>
								))}
							</tbody>
						</Table>

					</Card>
				</Col>


				{/* ------------------------------------ Günlük Alınan Cihaz ------------------------------------ */}


				<Col sm={12} md={6} lg={4}>
					<Card className='alert alert-light border card-report'>

						<Card.Title><BsCart2 /> Günlük Alınan Cihaz</Card.Title>
						<hr />
						<Table hover>
							<tbody className="fs-5">
								{buyDaily.map((item, i) => (
									<tr key={i}>
										<td onClick={() => navigate(`/company/device?filter=createDate&value=${item.day}`)}>
											<span className='table-item'>
												<BsCalendarDate />
												{item.day === moment(new Date()).format("YYYY-MM-DD")
													? "Bugün"
													: item.day === moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD")
														? "Dün"
														: moment(item.day).format("DD MMM - ddd")}
											</span>
										</td>
										<td className='table-count'>{item.count}</td>
									</tr>
								))}
							</tbody>
						</Table>

					</Card>
				</Col>


				{/* ------------------------------------ Aylık Alınan Cihaz ------------------------------------ */}


				<Col sm={12} md={6} lg={4}>
					<Card className='alert alert-warning card-report'>

						<Card.Title><BsCart2 /> Aylık Alınan Cihaz</Card.Title>
						<hr />
						<Table variant='warning' hover>
							<tbody className="fs-5">
								{buyMounthly.map((item, i) => (
									<tr key={i} onClick={() => navigate(`/company/device?filter=buyMonth&value=${item.mount}`)}>
										<td>
											<span className='table-item'><BsCalendar3 />{moment(item.mount).format("MMMM")} </span>
										</td>
										<td className='table-count'>{item.count}</td>
									</tr>
								))}
							</tbody>
						</Table>

					</Card>
				</Col>


				{/* ------------------------------------ Telefon Marka Dağılımı ------------------------------------ */}


				<Col sm={12} md={6} lg={4}>
					<Card className='alert alert-light border card-report'>

						<Card.Title><BsPieChart /> Telefon Marka Dağılımı</Card.Title>
						<hr />
						<Table hover>
							<tbody className="fs-5">
								{allDeviceBrandCount.map((item, i) => (item.title === "Diğer" && item.count === 0 ? null : <tr key={i}>
									<td onClick={() => item.title !== "Diğer" && navigate(`/company/device?filter=brand+deviceType+statusExcept&value=${item.title}+phone+sold`)}>
										<span className='table-item'><BsRecord /> {item.title} </span>
									</td>
									<td className='table-count'>{item.count}</td>
								</tr>
								))}
							</tbody>
						</Table>

					</Card>
				</Col>


				{/* ------------------------------------ Tarihe Göre Rapor ------------------------------------ */}


				<Col sm={12} md={6} lg={4}>
					<Card className='alert alert-warning card-report'>

						<Card.Title><BsCalendar3Range /> Tarihe Göre Rapor</Card.Title>
						<hr />

						<Table variant='warning' hover>
							<tbody className="fs-5">
								<tr>
									<td><span className='table-item'><BsCalendarDate /> <label htmlFor="dateStart">Başlangıç</label></span></td>
									<td className='table-count py-0'>
										<Form.Group controlId="dateStart" className="my-2">
											<Form.Control name="from" type="date" max={date.to} className='table-count bg-transparent border-0 fs-5 p-0' onChange={handleDate} value={date.from} />
										</Form.Group>
									</td>
								</tr>
								<tr>
									<td><span className='table-item'><BsCalendarDate /> <label htmlFor="dateEnd">Bitiş</label></span></td>
									<td className='table-count py-0'>
										<Form.Group controlId="dateEnd" className="my-2">
											<Form.Control name="to" type="date" min={date.from} max={moment(new Date()).format('YYYY-MM-DD')} className='table-count bg-transparent border-0 fs-5 p-0' onChange={handleDate} value={date.to} />
										</Form.Group>
									</td>
								</tr>
								<tr>
									<td onClick={() => navigate(`/company/device?filter=buyStartDate+buyEndDate&value=${date.from}+${date.to}`)}>
										<span className='table-item'><BsCart2 /> Alınan Cihaz</span>
									</td>
									<td className='table-count'>{profitDate.buyDevice}</td>
								</tr>
								<tr>
									<td onClick={() => navigate(`/company/device?filter=soldStartDate+soldEndDate&value=${date.from}+${date.to}`)}>
										<span className='table-item'><BsPhone /> Satılan Cihaz</span>
									</td>
									<td className='table-count'>{profitDate.soldDevice}</td>
								</tr>
								<tr>
									<td><span className='table-item'><BsSave /> Kazanç</span></td>
									<td className='table-count'>{formatter.format(profitDate.profit)}</td>
								</tr>
							</tbody>
						</Table>

					</Card>
				</Col>

			</Row>

		</>
	)
}
