const initialState = { user: {}, devices: [], loading: false, error: null }



export default (state = initialState, { type, payload }) => {
   switch (type) {


      case "GET_LIST_PENDING":
         return { ...state, loading: true }

      case "GET_LIST_FULFILLED":
         return { ...state, user: payload.data.user, devices: payload.data.devices.sort((a, b) => a.brand === "Samsung" ? -1 : 0), error: "", loading: false }

      case "GET_LIST_REJECTED":
         return { ...state, error: payload.message, loading: false }


      default:
         return state
   }
}