import React from 'react'
import alasql from 'alasql';
import { Alert, Card, ListGroup } from "react-bootstrap";
import { BsBroadcast } from 'react-icons/bs';
import { summary } from '../helpers/summary';
import { status, type } from "../helpers/data"



export default function MobilView({ devices, handleDevices, isAdmin }) {

   let groupedDevices = alasql(`
      SELECT brand, 
      ARRAY({ 
         user:user, 
         id:id, 
         deviceType:deviceType, 
         model:model, 
         storage:storage, 
         color:color, 
         price:price, 
         status:status, 
         watchSize:watchSize, 
         imei:imei, 
         serial:serial,
         listed:listed 
      }) devices, count(*) [count] 
      FROM ? x 
      GROUP BY brand 
      ORDER BY brand
   `, [devices])

   const page = window.location.href.split("/")[3];

   return (
      <div className='card-columns mb-3'>
         {groupedDevices.map((item, i) =>
            <Card bg={page === "admin" ? "danger" : "warning"} text={page === "admin" && "light"} className="mb-3 w-100" key={i} style={{ display: "inline-block" }}>
               <Card.Header className="text-center fw-bold p-1">{item.brand}</Card.Header>
               <ListGroup variant="flush">
                  {item.devices.map((elem, i) =>
                     elem.model && <ListGroup.Item key={i} onClick={() => handleDevices(elem.id)} className="d-flex align-items-center pointer card-item p-2">

                        <div>
                           {elem?.model} {elem?.watchSize ? elem?.watchSize : elem?.storage} {elem?.color}
                           <span className='text-muted small ms-1'>
                              {["headset", "watch"].includes(elem.deviceType)
                                 ? elem.serial && "[" + elem.serial.substr(elem.serial.length - 4, 4) + "] "
                                 : elem.imei && "[" + elem.imei.substr(elem.imei.length - 4, 4) + "] "
                              }
                           </span>
                           {isAdmin && elem.user?.company && <Alert variant="light" className="d-inline text-nowrap border px-1 p-0" title={elem.user?.company}>{summary(elem.user?.company, 12)}</Alert>}
                        </div>

                        <div className='d-flex align-items-center gap-1'>
                           {type.map((item, i) => item.id === elem.deviceType && item.id !== "phone" && <span key={i} className='text-muted'>{item.icon}</span>)}
                           {elem.listed === "open" && isAdmin && <BsBroadcast className='text-muted' />}
                           {<Alert
                              title={status.map(item => item.id === elem.status ? item.label : null).join("")}
                              variant={elem.status === "open" ? "text-dark bg-light border" : elem.status === "sold" ? "danger" : elem.status === "reserved" ? "warning" : ""}
                              className="fw-bold p-0 px-1 m-0">₺{elem.price.toLocaleString()}
                           </Alert>}
                        </div>

                     </ListGroup.Item>
                  )}
               </ListGroup>
            </Card>
         )}
      </div>
   )
}
