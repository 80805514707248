import axios from 'axios'

export const API = axios.create({ baseURL: '/api' })

if (process.env.NODE_ENV === 'development') { API.defaults.baseURL = 'https://poligsm.com/api' }


const devices = "/devices"
const users = "/users"
const reset = "/reset-password"
const change = "/change-password"
const list = "/devices/list"


// #region ---------------------------- AUTH -------------------------------- //


export function login(data) {
   return dispatch => {
      dispatch({
         type: "LOGIN",
         payload: API.post(`${users}/login`, data)
      }).then(res => { res.value.data.accessToken && localStorage.setItem("token", JSON.stringify(res.value.data)) })
         .catch(err => { console.log(err.response.data.error.message) })
   }
}


export function register(data) {
   return dispatch => {
      dispatch({
         type: "REGISTER",
         payload: API.post(users, data)
      }).then(() => setTimeout(() => { dispatch(clearMessage()) }, 3000))
         .catch(() => setTimeout(() => { dispatch(clearMessage()) }, 3000))
   }
}


export function logout(token) {
   setTimeout(() => { localStorage.removeItem("persist:b2b"); }, 1000);
   return dispatch => {
      dispatch({
         type: "LOGOUT",
         payload: API.post(`${users}/logout?access_token=${token?.id}`)
      })
   }
}


export function changePassword(token, data) {
   return dispatch => {
      dispatch({
         type: "CHANGE_PASSWORD",
         payload: API.post(`${users + change}?access_token=${token.id}`, data)
      }).then(() => setTimeout(() => { dispatch(clearMessage()) }, 3000))
         .catch(() => setTimeout(() => { dispatch(clearMessage()) }, 3000))
   }
}


export function resetPassword(data) {
   return dispatch => {
      dispatch({
         type: "RESET_PASSWORD",
         payload: API.post(users + reset, data)
      })
   }
}


// #endregion


// #region ---------------------------- CLEAR -------------------------------- //


export function clear() {
   setTimeout(() => { localStorage.removeItem("persist:b2b"); }, 1000);
   return dispatch => {
      dispatch({
         type: "CLEAR"
      })
   }
}


export function clearMessage() {
   return dispatch => {
      dispatch({
         type: "CLEAR_MESSAGE"
      })
   }
}


// #endregion


// #region ------------------------ ADMIN-DEVICES --------------------------- //


export function getDevices(token) {
   return dispatch => {
      let query = { include: { relation: "user", scope: { fields: ['company', 'address', 'phone'] } }, where: { or: [{ deleted: null }, { deleted: false }] } }
      dispatch({
         type: "GET_DEVICES",
         payload: API.get(`${devices}?access_token=${token.id}&filter=${JSON.stringify(query)}`)
      }).catch(err => { err.response.status === 401 && dispatch(logout(token)) })
   }
}


export function postDevices(token, data) {
   return dispatch => {
      dispatch({
         type: "POST_DEVICES",
         payload: API.post(`${devices}?access_token=${token.id}`, data)
      }).then(() => setTimeout(() => { dispatch(clearMessage()) }, 500))
         .catch(err => { err.response.status === 401 && dispatch(logout(token)) })
   }
}



export function putDevices(token, data) {
   return dispatch => {
      dispatch({
         type: "PUT_DEVICES",
         payload: API.patch(`${devices}/${data.id}?access_token=${token.id}`, data)
      }).then(() => setTimeout(() => { dispatch(clearMessage()) }, 500))
         .catch(err => { err.response.status === 401 && dispatch(logout(token)) })
   }
}


export function delDevices(token, id) {
   return dispatch => {
      dispatch({
         type: "DEL_DEVICES",
         payload: API.delete(`${devices}/${id}?access_token=${token.id}`)
      }).then(() => setTimeout(() => { dispatch(clearMessage()) }, 500))
   }
}


// #endregion


// #region ------------------------- ADMIN-USERS ---------------------------- //


export function getUsers(token) {
   return dispatch => {
      dispatch({
         type: "GET_USERS",
         payload: API.get(`${users}?access_token=${token.id}`)
      }).catch(err => { err.response.status === 401 && dispatch(logout(token)) })
   }
}


export function updateUsers(token, data) {
   return dispatch => {
      dispatch({
         type: "PUT_USERS",
         payload: API.post(`${users}/update?where={"_id":"${data._id}"}&access_token=${token.id}`, data)
      }).then(() => setTimeout(() => { dispatch(clearMessage()) }, 3000))
   }
}


export function delUsers(token, id) {
   return dispatch => {
      dispatch({
         type: "DEL_USERS",
         payload: API.delete(`${users}/${id}?access_token=${token.id}`)
      }).then(() => setTimeout(() => { dispatch(clearMessage()) }, 3000))
   }
}



// #endregion


// #region ------------------------ USER-DEVICE ----------------------------- //


export function getDevice(token) {
   return dispatch => {
      let query = { where: { or: [{ deleted: null }, { deleted: false }] } }
      dispatch({
         type: "GET_DEVICE",
         payload: API.get(`${users}/${token.userId + devices}?access_token=${token.id}&filter=${JSON.stringify(query)}`)
      }).catch(err => { err.response.status === 401 && dispatch(logout(token)) })
   }
}


export function postDevice(token, data) {
   return dispatch => {
      dispatch({
         type: "POST_DEVICE",
         payload: API.post(`${users}/${token.userId + devices}?access_token=${token.id}`, data)
      }).then(() => setTimeout(() => { dispatch(clearMessage()) }, 500))
         .catch(err => { err.response.status === 401 && dispatch(logout(token)) })
   }
}


export function putDevice(token, data) {
   return dispatch => {
      dispatch({
         type: "PUT_DEVICE",
         payload: API.put(`${users}/${token.userId + devices}/${data.id}?access_token=${token.id}`, data)
      }).then(() => setTimeout(() => { dispatch(clearMessage()) }, 500))
         .catch(err => { err.response.status === 401 && dispatch(logout(token)) })
   }
}


export function delDevice(token, id) {
   return dispatch => {
      dispatch({
         type: "DEL_DEVICE",
         payload: API.put(`${users}/${token.userId + devices}/${id}?access_token=${token.id}`, { deleted: true })
      }).then(() => setTimeout(() => { dispatch(clearMessage()) }, 500))
   }
}


// #endregion


// #region ---------------------------- USER -------------------------------- //



export function getUser(token) {
   return dispatch => {
      dispatch({
         type: "GET_USER",
         payload: API.get(`${users}/${token.userId}?access_token=${token.id}`)
      }).catch(err => { err.response.status === 401 && dispatch(logout(token)) })
   }
}


export function updateUser(token, data) {
   return dispatch => {
      dispatch({
         type: "PATCH_USER",
         payload: API.patch(`${users}/${token.userId}?access_token=${token.id}`, data)
      }).then(() => setTimeout(() => { dispatch(clearMessage()) }, 3000))
         .catch(err => { err.response.status === 401 && dispatch(logout(token)) })
   }
}



// #endregion


// #region ---------------------------- LIST -------------------------------- //


export function getDeviceList(id) {
   return dispatch => {
      dispatch({
         type: "GET_LIST",
         payload: API.get(id ? list + "?userId=" + id : list)
      })
   }
}


// #endregion