const INITIAL_STATE = { user: {}, devices: [], loading: false, sending: false, error: null, success: false }



export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {


    // #region --------------------------------- DEVICE --------------------------------//


    case "GET_DEVICE_PENDING":
      return { ...state, loading: true }

    case "GET_DEVICE_FULFILLED":
      return { ...state, devices: payload.data.reverse(), error: "", loading: false }

    case "GET_DEVICE_REJECTED":
      return { ...state, error: payload.message, loading: false }



    case "POST_DEVICE_PENDING":
      return { ...state, sending: true }

    case "POST_DEVICE_FULFILLED":
      return { ...state, devices: [payload.data, ...state.devices], sending: false, success: true }

    case "POST_DEVICE_REJECTED":
      return { ...state, error: payload.message, sending: false }



    case "PUT_DEVICE_PENDING":
      return { ...state, sending: true }

    case "PUT_DEVICE_FULFILLED":
      return { ...state, devices: state.devices.map(item => item.id === payload.data.id ? payload.data : item), sending: false, success: true }

    case "PUT_DEVICE_REJECTED":
      return { ...state, error: payload.message, sending: false }



    case "DEL_DEVICE_FULFILLED":
      return { ...state, devices: state.devices.filter(item => item.id !== payload.data.id), error: "", success: true }


    // #endregion

    
    // #region ---------------------------------- USER ---------------------------------//


    case "GET_USER_PENDING":
      return { ...state, loading: true }

    case "GET_USER_FULFILLED":
      return { ...state, user: payload.data, error: "", loading: false }

    case "GET_USER_REJECTED":
      return { ...state, error: "", loading: false }



    case "PATCH_USER_PENDING":
      return { ...state, sending: true }

    case "PATCH_USER_FULFILLED":
      return { ...state, user: payload.data, error: "", sending: false, success: true }

    case "PATCH_USER_REJECTED":
      return { ...state, error: "", sending: false }


    // #endregion


    // #region --------------------------------- LOGOUT --------------------------------//


    case "LOGOUT_FULFILLED":
      return { ...INITIAL_STATE }

    case "CLEAR":
      return { ...INITIAL_STATE }

    case "CLEAR_MESSAGE":
      return { ...state, error: "", sending: false, success: false }


    // #endregion


    default:
      return state
  }
}