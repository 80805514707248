import React, { useEffect, useState } from 'react'
import { Button, Form, Alert, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { changePassword } from '../../store/actions'
import { BsArrowLeftRight } from 'react-icons/bs'



const initialState = { oldPassword: "", newPassword: "" }

const initialValid = { valid: false, passwordConfirm: "" }

export default function PasswordChange(props) {

   const dispatch = useDispatch()

   const [validated, setValidated] = useState(initialValid);

   const [formData, setFormData] = useState(initialState)

   const { token, message, error, sending, success } = useSelector(state => state?.authReducer)

   const onInputChange = (e) => { setFormData({ ...formData, [e.target.name]: e.target.value }) }

   const handleSubmit = (e) => {
      e.preventDefault();

      if (formData.newPassword === validated.passwordConfirm) {
         dispatch(changePassword(token, formData))
         setFormData(initialState)
         setValidated(initialValid)
      }
   };

   useEffect(() => success && setTimeout(() => { props.onHide() }, 3000), [success])


   return (
      <Modal {...props} size="smd" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered >

         <div className="position-relative">
            <button type="button" className="btn-close absolute-close" aria-label="Close" onClick={() => props.onHide()}></button>
         </div>

         <Modal.Body className="p-4 text-center">

            <div className="my-4">
               <BsArrowLeftRight className='fs-1 auth-svg' />
            </div>

            <h3 className="mb-4">Change Password</h3>

            <Form onSubmit={handleSubmit}>

               <Form.Group controlId="regPersonPassword" className="mb-3">
                  <Form.Control name="oldPassword" type="password" minLength="6" maxLength="30" placeholder="Old password"
                     className="bg-light py-2"
                     onChange={onInputChange} value={formData.oldPassword} disabled={sending} required />
               </Form.Group>

               <Form.Group controlId="regPersonNewPassword" className="mb-3">
                  <Form.Control name="newPassword" type="password" minLength="6" maxLength="30" placeholder="New password"
                     className={`bg-light py-2` + (validated.valid ? ((formData.newPassword === validated.passwordConfirm) && formData.newPassword > 5 ? " is-valid" : " is-invalid") : "")}
                     onChange={onInputChange} value={formData.newPassword} disabled={sending} required />
               </Form.Group>

               <Form.Group controlId="regPersonRePassword" className="mb-4">
                  <Form.Control name="passwordConfirm" type="password" minLength="6" maxLength="30" placeholder="Confirm password"
                     className={`bg-light py-2` + (validated.valid ? ((formData.newPassword === validated.passwordConfirm) && validated.passwordConfirm > 5 ? " is-valid" : " is-invalid") : "")}
                     onChange={(e) => setValidated({ ...validated, [e.target.name]: e.target.value })} value={validated.passwordConfirm} disabled={sending} required />
               </Form.Group>

               {message
                  ? <Alert variant="success" className="small mt-2 mb-5">{message}</Alert>
                  : error
                     ? <Alert variant="danger" className="small mt-2 mb-5">{error}</Alert>
                     : <Button variant="primary" type='submit' className="mb-5 px-4 mx-auto" onClick={() => setValidated({ ...validated, valid: true })} disabled={sending} >Change Password</Button>}

            </Form>

         </Modal.Body>

      </Modal >
   )
}
